import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { User } from '../models/User';
import { AuthService } from '../services/auth.service';
import { MetamaskService } from '../services/metamask.service';

@Component({
  selector: 'app-metamask-connect',
  templateUrl: './metamask-connect.component.html',
  styleUrls: ['./metamask-connect.component.scss']
})
export class MetamaskConnectComponent implements OnInit {
  providerState: number = -1;
  selectedAddress: string | undefined;
  user: User | undefined;
  copied = false;
  fromComputer = !/Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent);

  @Output()
  connectedTo: EventEmitter<string> = new EventEmitter();

  constructor(private metamaskService: MetamaskService,
    private dialogService: MatDialog,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.authService.getCurrentUser().then((user: any) => {
      this.user = user;
      this.metamaskConfig();
    });
  }

  metamaskConfig() {
    this.metamaskService.providerExists().then((resp: any) => {
      this.providerState = 1;
      resp.enable().then(() => {
        this.providerState = 2;
        this.selectedAddress = resp.selectedAddress;
        if (this.user && this.selectedAddress) {
          if (this.user.linkedWalletsAddresses) {
            if (!this.user.linkedWalletsAddresses.includes(this.selectedAddress)) {
              this.user.linkedWalletsAddresses.push(this.selectedAddress);
              this.authService.updateUser(this.user);
              window.location.reload();
            }
          } else {
            this.user.linkedWalletsAddresses = [this.selectedAddress];
            this.authService.updateUser(this.user);
            window.location.reload();
          }
        }
      });
    }).catch(() => {
      this.providerState = -1;
    });
  }

  copyAddress() {
    if (this.selectedAddress) {
      navigator.clipboard.writeText(this.selectedAddress);
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 2000)
    } 
  }

  closeDialog() {
    this.dialogService.closeAll();
  }
}
