import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-doc-viewer',
  templateUrl: './doc-viewer.component.html',
  styleUrls: ['./doc-viewer.component.scss']
})
export class DocViewerComponent implements OnInit, AfterViewInit {
  @ViewChild('frame')
  frame!: ElementRef;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<DocViewerComponent>, private renderer: Renderer2) {    
  }

  ngOnInit(): void {
    this.dialogRef.updateSize('', '90vh')
  }

  ngAfterViewInit(): void {
  }
}
