import { Component, Inject, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})
export class PlayerComponent implements OnInit {
  audioUrl: string | undefined;
  videoUrl: string | undefined;
  imageUrl: string | undefined;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.setPlayer();
  }

  setPlayer() {
    let ext = this.data.substring(this.data.indexOf('.'));
    if(['.ogg','.mpeg','.wab','.mp3'].includes(ext)) {
      this.audioUrl = this.data;
    } else if (['.avi','.mp4','.webm'].includes(ext)) {
      this.videoUrl = this.data;
    } else {
      this.imageUrl = this.data;
    }
  }

}

