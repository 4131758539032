import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Card } from '../models/Card';
import { User } from '../models/User';
import { AuthService } from '../services/auth.service';
import { CardService } from '../services/card.service';
import { ContractService } from '../services/contract.service';
import { EditEmailComponent } from './edit-email/edit-email.component';
import { EditPasswordComponent } from './edit-password/edit-password.component';
import { EditWalletComponent } from './edit-wallet/edit-wallet.component';

@Component({
  selector: 'app-mynst',
  templateUrl: './mynst.component.html',
  styleUrls: ['./mynst.component.scss']
})
export class MynstComponent implements OnInit {
  mines: Card[] | undefined;
  load = true;
  loadNFT = true;
  user: User | undefined;
  pictureForm: FormGroup;
  imagePreview: string | undefined;

  get hasWallet() {
    return this.user && this.user.linkedWalletsAddresses.length>0
  }

  constructor(private contractService: ContractService, private authService: AuthService, private cardService: CardService, private dialog: MatDialog) {
    this.pictureForm = new FormGroup({
      image: new FormControl('', Validators.required)
    })
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.isAuth();
  }

  isAuth() {
    this.authService.getCurrentUser().then((user: any) => {
      this.user = user;
      this.load = false;
      this.fillCards();
    });
  }

  fillCards(): void {
    // this.contractService.getMyNst().then((last: any) => {
    //   console.log(last);
      
    //   this.mines = last.filter((nst: any) => nst != null);
    //   this.load = false;
    // });
    if (this.user?._id)
    this.cardService.getPurchasedCards(this.user._id).then((data: any) => {
      this.mines = data.length>0 ? data : undefined;
      this.loadNFT = false;
    }).catch((err) => {
      this.loadNFT = false;
      console.log(err);
    })
  }

  editPicture() {
    if (this.user?._id)
    this.authService.editUserPicture(this.user?._id, this.pictureForm.get('image')?.value)
      .then(() => {
        this.isAuth();
        this.imagePreview = undefined;
      }).catch((error)=> console.log(error));
  }

  editEmail() {
    const dialogRef = this.dialog.open(EditEmailComponent);

    dialogRef.afterClosed().subscribe(() => {
      this.isAuth();
    })
  }

  editPassword() {
    const dialogRef = this.dialog.open(EditPasswordComponent);

    dialogRef.afterClosed().subscribe(() => {
      this.isAuth();
    })
  }

  editWallet() {
    const dialogRef = this.dialog.open(EditWalletComponent);

    dialogRef.afterClosed().subscribe(() => {
      this.isAuth();
    })
  }

  onImagePick(event: Event) {
    const htmlElement = (event.target as HTMLInputElement);
    if (htmlElement.files) {
      const file = htmlElement.files[0];
      this.pictureForm.get('image')?.patchValue(file);
      this.pictureForm.get('image')?.updateValueAndValidity();
      const reader = new FileReader();
      reader.onload = () => {
        if (this.pictureForm.get('image')?.valid) {
          this.imagePreview = reader.result as string;
        } else {
          this.imagePreview = undefined;
        }
      };
      reader.readAsDataURL(file);
    }
  }
}
