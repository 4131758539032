import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-edit-password',
  templateUrl: './edit-password.component.html',
  styleUrls: ['./edit-password.component.scss']
})
export class EditPasswordComponent implements OnInit {
  user: User | undefined;
  pwForm: FormGroup;
  success: boolean = false;
  error: string | undefined;

  constructor(private authService: AuthService, private dialog: MatDialog, private translateService: TranslateService) {
    this.pwForm = new FormGroup({
      current: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]),
      new: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]),
      confirm: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(50)])
    })
  }

  ngOnInit(): void {
    this.authService.getCurrentUser().then((user: any) => {
      this.user = user;
    });

    this.pwForm.valueChanges.subscribe((change) => {
      this.error = undefined;
    })
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  editPw() {
    if (this.user?._id)
    this.authService.editPw(this.user?._id, this.pwForm.get('current')?.value, this.pwForm.get('new')?.value)
      .then((data) => {
        if (data) {
          this.success = true;
        }
      })
      .catch((error) => {
        if (error.error == 0) this.error = this.translateService.instant('Profile.EncryptionErr');
        if (error.error == -1) this.error = this.translateService.instant('Profile.WrongPw');
        if (error.error == -2) this.error = this.translateService.instant('Profile.ServerErr');
      })
  }

}
