import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { ArtistPageComponent } from './artist-page/artist-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CardComponent } from './card/card.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatDialogModule } from '@angular/material/dialog';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { AddArtistComponent } from './admin/add-artist/add-artist.component';
import { AddArtworkComponent } from './admin/add-artwork/add-artwork.component';
import { ModifyArtistComponent } from './admin/modify-artist/modify-artist.component';
import { ModifyArtworkComponent } from './admin/modify-artwork/modify-artwork.component';
import { WindowRef } from './services/window.service';
import { MynstComponent } from './mynst/mynst.component';
import { LanguagesComponent } from './languages/languages.component';
import { RecoverComponent } from './recover/recover.component';
import { ConfirmComponent } from './signup/confirm/confirm.component';
import { AboutComponent } from './footer/about/about.component';
import { TeamComponent } from './footer/team/team.component';
import { BurgerMenuComponent } from './footer/burger-menu/burger-menu.component';
import { ContactComponent } from './footer/contact/contact.component';
import { TermsComponent } from './footer/terms/terms.component';
import { ArtistsListComponent } from './artists-list/artists-list.component';
import { AccessScreenComponent } from './access-screen/access-screen.component';
import { EditEmailComponent } from './mynst/edit-email/edit-email.component';
import { EditPasswordComponent } from './mynst/edit-password/edit-password.component';
import { MetamaskConnectComponent } from './metamask-connect/metamask-connect.component';
import { HelpMetamaskComponent } from './help-metamask/help-metamask.component';
import { PaypalComponent } from './paypal/paypal.component';
import { SuccessComponent } from './success/success.component';
import { EditWalletComponent } from './mynst/edit-wallet/edit-wallet.component';
import { PlayerComponent } from './player/player.component';
import { CarouselComponent } from './carousel/carousel.component';
import { PurchaseComponent } from './purchase/purchase.component';
import { ResourceLoader } from '@angular/compiler';
import { BuyPhysicalComponent } from './artist-page/buy-physical/buy-physical.component';
import { SafePipe } from './pipes/safe-pipe';
import { DocViewerComponent } from './footer/terms/doc-viewer/doc-viewer.component';

import { LightgalleryModule } from 'lightgallery/angular/11';
// import { PdfViewerModule } from 'ng2-pdf-viewer';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ArtistPageComponent,
    CardComponent,
    SignupComponent,
    LoginComponent,
    AdminComponent,
    AddArtistComponent,
    AddArtworkComponent,
    ModifyArtistComponent,
    ModifyArtworkComponent,
    MynstComponent,
    LanguagesComponent,
    RecoverComponent,
    ConfirmComponent,
    AboutComponent,
    TeamComponent,
    BurgerMenuComponent,
    ContactComponent,
    TermsComponent,
    ArtistsListComponent,
    AccessScreenComponent,
    EditEmailComponent,
    EditPasswordComponent,
    MetamaskConnectComponent,
    HelpMetamaskComponent,
    PaypalComponent,
    SuccessComponent,
    EditWalletComponent,
    PlayerComponent,
    CarouselComponent,
    PurchaseComponent,
    BuyPhysicalComponent,
    SafePipe,
    DocViewerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    LightgalleryModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    })
  ],
  providers: [WindowRef,ResourceLoader],
  bootstrap: [AppComponent]
})
export class AppModule { }
