import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Card } from '../models/Card';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class CardService {
    userId: string = '';
    constructor(private http: HttpClient, private authService: AuthService) {
        this.authService.getCurrentUser().then((user: any) => {
            this.userId = user._id;
        })
    }

    // Return all cards in DB (id, artistName, artwork), 'artwork' being the artwork's name
    getCards() {
        return new Promise((resolve, reject) => {
            this.http.get(environment.protocol + '://' + environment.path + '/api/artwork').subscribe(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        })
    }

    getCardsFromArtistId(_id: string | undefined) {
        return new Promise((resolve, reject) => {
            this.http.get(environment.protocol + '://' + environment.path + '/api/cards/' + _id).subscribe(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        })
    }

    getCardById(_id: string | undefined): Promise<Card> {
        return new Promise((resolve, reject) => {
            this.http.get<Card>(environment.protocol + '://' + environment.path + '/api/artwork/' + _id).subscribe(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        })
    }

    /* 'sort' can be one of these options ['selected', 'hot', 'last', 'current']
        Return the cards sorted by the category of your choice:
        Selected: last artwork selected by Neo Satellite's Team
        Last: last (4max) artworks
        Hot: last (4max) artworks the most popular
        Current: last (4max) artworks being currently open to bids */
    getSortedCards(sort: string) {
        return new Promise((resolve, reject) => {
            this.http.get(environment.protocol + '://' + environment.path + '/api/card/' + sort).subscribe(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        })
    }

    // Create a new card with its associated picture
    createNewArtworkWithFile(artwork: Card, files: Array<File>) {
        let token = window.sessionStorage.getItem('authToken');
        return new Promise((resolve, reject) => {
            const artworkData = new FormData();
            artworkData.append('artwork', JSON.stringify(artwork));
            artworkData.append('myFiles', files[0], artwork.artwork);
            if (files[1]) artworkData.append('myFiles', files[1], 'nft_' + artwork.artwork);
            if (files[2]) artworkData.append('myFiles', files[2], 'preview_' + artwork.artwork);
            this.http.post(environment.protocol + '://' + environment.path + '/api/artwork', artworkData, {
                headers: {
                    Authorization: token ? token : 'none',
                    UserID: this.userId
                }
            }).subscribe(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    // Modify a card 
    modifyArtworkWithFile(id: string, artwork: Card, image: File | string, imgIndex?: number) {
        let token = window.sessionStorage.getItem('authToken');
        return new Promise((resolve, reject) => {
            let artworkData: Card | FormData;
            if (imgIndex == -1 && typeof image == 'string') {    // without new picture
                artwork.url = image;
                artworkData = artwork;
            } else {                            // with new picture
                artworkData = new FormData();
                artworkData.append('artwork', JSON.stringify(artwork));
                let prefix = imgIndex == 0 ? '' : imgIndex == 1 ? 'nft_' : 'preview_'
                artworkData.append('myFiles', image, prefix + artwork.artwork);
            }
            this.http.put(environment.protocol + '://' + environment.path + '/api/artwork/' + id, artworkData, {
                headers: {
                    Authorization: token ? token : 'none',
                    UserID: this.userId
                }
            }).subscribe(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    console.log(error)
                    reject(error);
                }
            );
        });
    }

    // Get purchased cards by user
    getPurchasedCards(userId: string) {
        let token = window.sessionStorage.getItem('authToken');
        return new Promise((resolve, reject) => {
            this.http.get(environment.protocol + '://' + environment.path + '/api/artwork/purchased/' + userId, {
                headers: {
                    Authorization: token ? token : 'none',
                    UserID: this.userId
                }
            }).subscribe(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        })
    }

    getPurchaseByPayId(payId: string) {
        let token = window.sessionStorage.getItem('authToken');
        return new Promise((resolve, reject) => {
            this.http.get(environment.protocol + '://' + environment.path + '/api/artwork/purchasePayId/' + payId, {
                headers: {
                    Authorization: token ? token : 'none',
                    UserID: this.userId
                }
            }).subscribe(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        })
    }

    addPurchase(cardId: string, purchaseID: string) {
        return new Promise((resolve, reject) => {
            this.http.put(environment.protocol + '://' + environment.path + '/api/artwork/addPurchase/' + cardId, { id: purchaseID }).subscribe(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    console.log(error)
                    reject(error);
                }
            );
        })
    }

    checkArtworkPassword(id: string, pw: string) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.protocol + '://' + environment.path + '/api/artwork/checkPw', { id: id, password: pw })
                .subscribe((res) => {
                    console.log(res);
                    resolve(res)
                }, (err) => {
                    reject(err)
                })
        })
    }

    deleteCard(id: string) {
        let token = window.sessionStorage.getItem('authToken');
        return new Promise((resolve, reject) => {
            this.http.delete(environment.protocol + '://' + environment.path + '/api/artwork/' + id, {
                headers: {
                    Authorization: token ? token : 'none',
                    UserID: this.userId
                }
            }).subscribe(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        })
    }
}