import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  @Input() _id: string | undefined = '';
  @Input() url: string | undefined = '';
  @Input() category: string | undefined = '';
  @Input() artist: string | undefined = '';
  @Input() artwork: string | undefined = '';
  @Input() currency: string | undefined = '';
  @Input() tokenId: string | undefined = '';
  @Input() price: number | undefined = 0;
  @Input() cryptovalue: number | undefined = 0;
  @Input() sold: number = 0;
  @Input() totalCount: number | undefined;
  @Input() status: 'available'|'unavailable'|'incoming'|'soldout' = 'available';

  @Input() pw: any;


  loaded = false;
  
  constructor() {
  }
}
