import { Injectable } from "@angular/core";

const MATIC_ID = 'aave-polygon-wmatic';
const EURO_ID = 'eur';
const USD_ID = 'usd';

@Injectable({
    providedIn: 'root'
})
export class ConvertService {
    lastConvertResult = {};
    usd2eur: number | undefined;
    usd2matic: number | undefined;
    eur2matic: number | undefined;

    constructor() { }

    // API based price converter
    getConvertedPrice() {
        return new Promise((resolve, reject) => {
            fetch('https://api.coingecko.com/api/v3/simple/price?ids=' + MATIC_ID + '&vs_currencies=' + EURO_ID + '%2C' + USD_ID).then(
                (resp) => {
                    resp.body?.getReader().read().then((data: any) => {
                        let uint8array = data.value;
                        var string = new TextDecoder().decode(uint8array);
                        this.lastConvertResult = JSON.parse(string);
                        this.setConvert(this.lastConvertResult);
                        resolve(setTimeout(() => { this.getConvertedPrice() }, 5000));
                    })
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    // Save the API results
    setConvert(apiResult: any) {
        this.eur2matic = apiResult[MATIC_ID].eur;
        this.usd2matic = apiResult[MATIC_ID].usd;
        if (this.eur2matic && this.usd2matic) {
            this.usd2eur = this.eur2matic / this.usd2matic;
        }
    }
}