import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../services/auth.service';
import { EmailService } from '../services/email.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent {
  form: FormGroup;
  subscribeSuccess: boolean = false;
  errorMessage: string | undefined;

  constructor(private auth: AuthService, private translateService: TranslateService, private emailService: EmailService) {
    this.form = new FormGroup({
      pseudo: new FormControl('', Validators.minLength(1)),
      email: new FormControl('', Validators.email),
      password: new FormControl('', Validators.minLength(6)),
      confirmPassword: new FormControl('', [Validators.minLength(6)]),
      profile: new FormControl('', Validators.required)
    })

    this.form.get('profile')?.setValue('buyer');
  }

  onSignup() {
    this.errorMessage = undefined;
    this.auth.createNewUser(
      this.form.get('pseudo')?.value,
      this.form.get('email')?.value,
      this.form.get('password')?.value,
      'buyer'
    ).then(
      (res) => {
        this.subscribeSuccess = true;
        // this.emailService.sendConfirmLink(this.form.get('email')?.value).then((sendLinkConfirm) => {
        //   this.subscribeSuccess = true;
        // }).catch(
        //   () => {
        //     this.translateService.get('Logs.SendingError').subscribe((error) => {
        //       this.errorMessage = error;
        //     })
        //   });
      }
    ).catch(
      (err) => {
        console.log(err);
        
        this.translateService.get('Logs.ExistingEmail').subscribe((error) => {
          this.errorMessage = error;
        });
      }
    );
  }
}
