import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['./paypal.component.scss']
})
export class PaypalComponent implements OnInit {
  @Input()
  price: number = 100;
  @Input() //delivery cost
  shipping_tax: number = 4.95;
  @Input()
  currency: string = '€';
  @Input()
  artworkId: string = 'N/A';
  @Input()
  name?: string = 'NEOSAT ARTWORK';

  vat = 0.2; // TVA 20%

  @Output()
  approved: EventEmitter<any> = new EventEmitter();

  @Output()
  hasError: EventEmitter<any> = new EventEmitter();

  @ViewChild('paypalRef', { static: true }) private paypalRef: ElementRef | undefined;

  constructor() { }


  ngOnInit(): void {
    this.setPaypalButtons();
  }

  setPaypalButtons() {
    window.paypal.Buttons({
      style: {
        layout: 'vertical',
        color: 'black',
        shape: 'rect',
        label: 'paypal'
      },
      createOrder: (data: any, actions: any) => {
        return actions.order.create({
          application_context: {
            brand_name: "Neo Satellite",
            locale: "fr-FR",
            landing_page: "NO_PREFERENCE",
            user_action: "PAY_NOW",
            return_url: "https://neosatellite.io",
            cancel_url: "https://neosatellite.io"
          },
          purchase_units: [
            {
              amount: {
                value: this.price + this.shipping_tax,
                currency_code: this.currency == '$' ? 'USD' : 'EUR',
                breakdown: {
                  // values must sum to the amount.value
                  item_total: {
                    currency_code: this.currency == '$' ? 'USD' : 'EUR',
                    value: Math.round((this.price / (this.vat + 1)) * 100) / 100
                  },
                  shipping: {
                    currency_code: this.currency == '$' ? 'USD' : 'EUR',
                    value: this.shipping_tax
                  },
                  tax_total: {
                    currency_code: this.currency == '$' ? 'USD' : 'EUR',
                    value: Math.round((this.price - (this.price / (this.vat + 1))) * 100) / 100
                  }
                }
              },
              items: [
                {
                  name: this.name,
                  sku: this.artworkId,
                  // description: 'Message personnalisé en bas du mail PayPal ',
                  unit_amount: {
                    // value + tax.value must sum to item_total
                    currency_code: this.currency == '$' ? 'USD' : 'EUR',
                    value: Math.round((this.price / (this.vat + 1)) * 100) / 100,
                    tax: {
                      currency_code: this.currency == '$' ? 'USD' : 'EUR',
                      value: Math.round((this.price - (this.price / (this.vat + 1))) * 100) / 100,
                    }
                  },
                  quantity: 1
                }
              ]
            }
          ]
        })
      },
      onApprove: (data: any, actions: any) => {
        return actions.order.capture().then((tr: any) => {
          this.approved.emit([data, tr]);
          // console.log(tr);
        })
          .catch((err: any) => {
            this.hasError.emit([data, err]);
            // console.log(err);
          });
      },
      onError: (err: any) => {
        console.log('Error:', err.name);
        console.log('Message:', err.message);
        alert(err.message);
        console.log('Stack:', err.stack);
        console.log('Response:', err.response);
          this.hasError.emit(err);
        // console.log(error);
      },
      onCancel: (error: any) => {
        console.log(error);
        console.log('CANCEL');
        // console.log(error);
      }
    }).render(this.paypalRef?.nativeElement);
  }

}