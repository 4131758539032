import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Card } from 'src/app/models/Card';
import { AuthService } from 'src/app/services/auth.service';
import { CardService } from 'src/app/services/card.service';
import { ConvertService } from 'src/app/services/convert.service';
import { UrlService } from 'src/app/services/url.service';

class ReducedCard {
  id: string | undefined;
  artistName: string | undefined;
  artwork: string | undefined;
}

@Component({
  selector: 'app-modify-artwork',
  templateUrl: './modify-artwork.component.html',
  styleUrls: ['./modify-artwork.component.scss']
})
export class ModifyArtworkComponent implements OnInit {
  genres: string[] = [];
  modifyForm: FormGroup;
  imagePreview: string | undefined;
  isAuth: boolean = false;
  artworks: ReducedCard[] = [];
  artwork: Card | undefined;
  imgIndex = -1;

  constructor(private auth: AuthService,
    private router: Router,
    private urls: UrlService,
    private convertService: ConvertService,
    private cardService: CardService) {
      this.convertService.getConvertedPrice();
    if (this.auth.user?.profile == "admin") {
      this.isAuth = true;
    } else {
      this.router.navigate(['**'])
    }
    this.modifyForm = new FormGroup({
      name: new FormControl('', [Validators.required, Validators.minLength(1)] ),
      artwork: new FormControl('', Validators.required),
      image: new FormControl(''),
      file: new FormControl(''),
      previewFile: new FormControl(''),
      descriptionEN: new FormControl(''),
      descriptionFR: new FormControl(''),
      category: new FormControl('', Validators.required),
      currency: new FormControl('', Validators.required),
      price: new FormControl('', [Validators.required, Validators.min(0)]),
      quantity: new FormControl(''),
      selected: new FormControl(''),
      popularity: new FormControl('', [Validators.required, Validators.min(0), Validators.max(1)]),
      currentBid: new FormControl(''),
      status: new FormControl('', Validators.required)
    });
  }

  ngOnInit(): void {
    this.genres = this.urls.getGenres();
    this.cardService.getCards().then((data: any) => {this.artworks = data});
  }

  modifyArtwork() {
    let artwork = this.setArtwork();
    if (this.artwork?._id) {
      this.cardService.modifyArtworkWithFile(this.artwork._id, artwork, this.imgIndex==-1 ? this.modifyForm.get('image')?.value : 
                                                                        this.imgIndex==0 ? this.modifyForm.get('image')?.value : 
                                                                        this.imgIndex==1 ? this.modifyForm.get('file')?.value : 
                                                                        this.modifyForm.get('previewFile')?.value, this.imgIndex).then(() => {
        confirm(artwork.artwork + ' bien modifié !');
        this.modifyForm.reset();
        this.router.navigate(['admin'])
        window.scrollTo(0, 0);
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  setArtwork(): Card {
    if (this.artwork){
      let artwork = new Card();
      artwork.artwork = this.modifyForm.get('name')?.value;
      artwork.descriptionEN = this.modifyForm.get('descriptionEN')?.value;
      artwork.descriptionFR = this.modifyForm.get('descriptionFR')?.value;
      artwork.category = this.modifyForm.get('category')?.value;
      artwork.artistId = this.modifyForm.get('artist')?.value;
      artwork.currency = this.modifyForm.get('currency')?.value;
      artwork.price = this.modifyForm.get('price')?.value;
      // SET CRYPTO VALUE
      if(artwork.currency == '€' && artwork.price && this.convertService.eur2matic) {
        artwork.cryptoValue = artwork.price/this.convertService.eur2matic;
      } else if (artwork.currency == '$' && artwork.price && this.convertService.usd2matic) {
        artwork.cryptoValue = artwork.price/this.convertService.usd2matic;
      } else {
        artwork.cryptoValue = artwork.price;
      }
      artwork.quantity = this.modifyForm.get('quantity')?.value;
      artwork.selected = this.modifyForm.get('selected')?.value;
      artwork.popularity = this.modifyForm.get('popularity')?.value;
      artwork.currentBid = this.modifyForm.get('currentBid')?.value;
      artwork.status = this.modifyForm.get('status')?.value;
      artwork.url = this.artwork.url;
      artwork.nftUrl = this.artwork.nftUrl;
      artwork.tokenIds = this.artwork?.tokenIds ? this.artwork?.tokenIds : [];
      artwork.tokenLast = this.artwork.tokenLast;
      artwork.nftPreviewUrl = this.artwork.nftPreviewUrl;
      artwork.artistName = this.artwork?.artistName;

      return artwork;
    }
    else
      return (new Card());
  }

  onImagePick(event: Event) {
    const htmlElement = (event.target as HTMLInputElement);
    if (htmlElement.files) {
      const file = htmlElement.files[0];
      this.modifyForm.get('image')?.patchValue(file);
      this.modifyForm.get('image')?.updateValueAndValidity();
      const reader = new FileReader();
      reader.onload = () => {
        if (this.modifyForm.get('image')?.valid) {
          this.imagePreview = reader.result as string;
          this.imgIndex = 0;
        } else {
          this.imagePreview = undefined;
        }
      };
      reader.readAsDataURL(file);
    }
  }
  
  onFilePick(event: Event) {
    const htmlElement = (event.target as HTMLInputElement);
    if (htmlElement.files) {
      const file = htmlElement.files[0];
      this.modifyForm.get('file')?.patchValue(file);
      this.modifyForm.get('file')?.updateValueAndValidity();
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log('Fichier NFT bien ajouté !');
        this.imgIndex = 1;
      };
      reader.readAsDataURL(file);
    }
  }

  onPreviewFilePick(event: Event) {
    const htmlElement = (event.target as HTMLInputElement);
    if (htmlElement.files) {
      const file = htmlElement.files[0];
      this.modifyForm.get('previewFile')?.patchValue(file);
      this.modifyForm.get('previewFile')?.updateValueAndValidity();
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log('Fichier Preview bien ajouté !');
        this.imgIndex = 2;
      };
      reader.readAsDataURL(file);
    }
  }

  fillArtwork() {
    this.cardService.getCardById(this.modifyForm.get('artwork')?.value).then((artwork: any) => {
      this.artwork = new Card();
      this.artwork._id = artwork._id;
      this.artwork.artwork = artwork.artwork;
      this.artwork.descriptionEN = artwork.descriptionEN;
      this.artwork.descriptionFR = artwork.descriptionFR;
      this.artwork.category = artwork.category;
      this.artwork.currency = artwork.currency;
      this.artwork.price = artwork.price;
      this.artwork.quantity = artwork.quantity;
      this.artwork.popularity = artwork.popularity;
      this.artwork.selected = artwork.selected;
      this.artwork.currentBid = artwork.currentBid;
      this.artwork.tokenIds = artwork.tokenIds;
      this.artwork.tokenLast = artwork.tokenLast;
      this.artwork.status = artwork.status;
      this.artwork.url = artwork.url;
      this.artwork.nftUrl = artwork.nftUrl;
      this.artwork.nftPreviewUrl = artwork.nftPreviewUrl;
      this.imagePreview = artwork.url;
      this.fillForm();
    })
  }

  fillForm() {
    this.modifyForm.get('name')?.setValue(this.artwork?.artwork);
    this.modifyForm.get('descriptionEN')?.setValue(this.artwork?.descriptionEN);
    this.modifyForm.get('descriptionFR')?.setValue(this.artwork?.descriptionFR);
    this.modifyForm.get('category')?.setValue(this.artwork?.category);
    this.modifyForm.get('image')?.setValue(this.artwork?.url);
    this.modifyForm.get('file')?.setValue(this.artwork?.nftUrl);
    this.modifyForm.get('previewFile')?.setValue(this.artwork?.nftPreviewUrl);
    this.modifyForm.get('currency')?.setValue(this.artwork?.currency);
    this.modifyForm.get('price')?.setValue(this.artwork?.price);
    this.modifyForm.get('quantity')?.setValue(this.artwork?.quantity);
    this.modifyForm.get('selected')?.setValue(this.artwork?.selected);
    this.modifyForm.get('popularity')?.setValue(this.artwork?.popularity);
    this.modifyForm.get('currentBid')?.setValue(this.artwork?.currentBid);
    this.modifyForm.get('status')?.setValue(this.artwork?.status);
  }

  deleteArtwork() {
    if (confirm('Etes vous sûr de vouloir supprimer cette oeuvre?')) {
      if (this.artwork?._id)
        this.cardService.deleteCard(this.artwork?._id).then(() => {
          confirm(this.artwork?.artwork + ' bien supprimée !');
          this.modifyForm.reset();
          this.router.navigate(['admin'])
          window.scrollTo(0, 0);
        }).catch((error) => {
          confirm(error.error.error.message);
        });;
    }
  }
}
