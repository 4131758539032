export class User {
    _id: string = '';
    email?: string;
    token?: string;
    pseudo?: string;
    profile?: string;
    photoUrl?: string;
    linkedWalletsAddresses: string[] = [];
    currentPurchaseUrl?: string;
    purchasedTokenIds?: string[];
    status?: string;
    creationDate?: Date;
    modificationDate?: Date;
    active?: boolean;
}