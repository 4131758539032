import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EmailService } from './email.service';

@Injectable({
    providedIn: 'root'
})
export class InvoiceService {

    private defaultURL = 'https://api-m.paypal.com/v2/invoicing/invoices';

    constructor(private http: HttpClient, private emailService: EmailService) { }

    getDate() {
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm: number | string = today.getMonth() + 1; // Months start at 0!
        let dd: number | string = today.getDate();
        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        const formattedToday = yyyy + '-' + mm + '-' + dd;

        return formattedToday
    }

    createInvoice(infos: any) {
        this.defaultInvoice.detail.invoice_date = this.getDate();
        this.defaultInvoice.primary_recipients[0].billing_info = infos.payer;
        this.defaultInvoice.primary_recipients[0].shipping_info = infos.purchase_units[0].shipping;

        infos.purchase_units[0].items.forEach((el: any, i: number) => {
            this.defaultInvoice.items[i] = infos.purchase_units[0].items[i];
            this.defaultInvoice.items[i].unit_of_measure = "QUANTITY"
            this.defaultInvoice.items[i].quantity = '1';
            this.defaultInvoice.items[i].tax = {
                name: 'TVA',
                percent: '20',
                amount: {
                    currency_code: 'EUR',
                    value: Math.round(el.unit_amount.value * 20) / 100
                }
            };
        })

        this.defaultInvoice.amount = {
            value: infos.purchase_units[0].amount.value,
            currency_code: 'EUR',
            breakdown: {
                shipping: {
                    amount: infos.purchase_units[0].amount.breakdown.shipping,
                },
                item_total: infos.purchase_units[0].amount.breakdown.item_total,
                tax_total: infos.purchase_units[0].amount.breakdown.tax_total,
            },
        }

        return new Promise((resolve, reject) => {
            // getToken
            let body = new HttpParams()
                .set('grant_type', 'client_credentials');

            this.http.post('https://api.paypal.com/v1/oauth2/token', body.toString(),
                {
                    headers: {
                        'Authorization': `Basic ${btoa(environment.ppc + ':' + environment.pps)}`,
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            ).subscribe(
                (response: any) => {
                    let auth = `${response.token_type} ${response.access_token}`;
                    // create invoice
                    this.http.post(this.defaultURL, this.defaultInvoice,
                        {
                            headers: {
                                'Authorization': auth,
                                'Content-Type': 'application/json',
                                'Prefer': 'return=representation'
                            }
                        })
                        .subscribe((creationResp: any) => {
                            let invoiceId = creationResp.id;
                            this.recordPayment(invoiceId, auth);

                            let linkIDarray = invoiceId.split('-');
                            linkIDarray.shift();

                            let link = `https://www.paypal.com/invoice/p/#${linkIDarray.join('')}`;
                            this.emailService.sendPurchaseConfirmationMail(this.defaultInvoice.primary_recipients[0].billing_info.email_address, link).then(() => {
                                console.log('Mail bien envoyé');
                                resolve('OK');
                            }).catch(() => {
                                console.log("Erreur dans l'envoi de mail");
                                resolve("Erreur dans l'envoi de mail");
                            });
                            
                        }, () => {
                            reject(1);
                        })
                },
                () => {
                    reject(0);
                }
            );
        });
    }


    recordPayment(invoiceId: any, auth: string) {
        let body = {
            method: "CREDIT_CARD",
            payment_date: this.getDate(),
            amount: {
                currency_code: this.defaultInvoice.amount.currency_code,
                value: this.defaultInvoice.amount.value
            }
        }
        // record payment
        this.http.post(`${this.defaultURL}/${invoiceId}/payments`, body, {
            headers: {
                'Authorization': auth,
                'Content-Type': 'application/json',
            }
        }).subscribe();
    }


    defaultInvoice = {
        detail: {
            currency_code: "EUR",
            //   invoice_number: "#123",
            //   reference: "deal-ref",
            invoice_date: "17/02/2023",
            note: `Neo Satellite, SAS au capital de 1000 euros. 
                31 rue Octave Feuillet, 75116, Paris
                RCS : Paris B 910 047 091. 
                SIRET : 910 047 091 000012. 
                Paiement effecuté. 
                Envoi du produit sous 30 jours.
                `, // Mentions légales
            term: "Pas de remboursement après 30 jours",
            // memo: "This is a long contract",
            //   payment_term: {
            //     term_type: "NET_10",
            //     due_date: "2018-11-22"
            //   }
        },
        invoicer: {
            name: {
                given_name: "NEO",
                surname: "SATELLITE"
            },
            address: {
                address_line_1: "NEO SATELLITE",
                address_line_2: "31 RUE OCTAVE FEUILLET",
                admin_area_2: "PARIS",
                //    admin_area_1: "CA",
                postal_code: "75116",
                country_code: "FR"
            },
            email_address: "contact@neosatellite.io",
            //   phones: [
            //     {
            //       country_code: "001",
            //       national_number: "4085551234",
            //       phone_type: "MOBILE"
            //     }
            //   ],
            website: "neosatellite.io",
            tax_id: "FR43910047091",
            logo_url: "https://neosatellite.io/assets/icons/neosat-logo.png",
            additional_notes: "SIREN: 910047091"
        },
        primary_recipients: [
            {
                billing_info: {
                    name: {
                        given_name: "Stephanie",
                        surname: "Meyers"
                    },
                    address: {
                        address_line_1: "1234 Main Street",
                        admin_area_2: "Anytown",
                        admin_area_1: "CA",
                        postal_code: "98765",
                        country_code: "US"
                    },
                    email_address: "bill-me@example.com",
                    //   phones: [
                    //     {
                    //       country_code: "001",
                    //       national_number: "4884551234",
                    //       phone_type: "HOME"
                    //     }
                    //   ],
                    //   additional_info_value: "add-info"
                },
                shipping_info: {
                    name: {
                        given_name: "Stephanie",
                        surname: "Meyers"
                    },
                    address: {
                        address_line_1: "1234 Main Street",
                        admin_area_2: "Anytown",
                        admin_area_1: "CA",
                        postal_code: "98765",
                        country_code: "US"
                    }
                }
            }
        ],
        items: [
            {
                name: "Yoga Mat",
                // description: "Elastic mat to practice yoga.",
                quantity: "1",
                unit_amount: {
                    currency_code: "USD",
                    value: "50.00"
                },
                tax: {
                    name: "Sales Tax",
                    percent: "7.25",
                    amount: {
                        value: 10.00,
                        currency_code: "EUR"
                    }
                },
                discount: {
                    percent: "5"
                },
                unit_of_measure: "QUANTITY"
            },
        ],
        // configuration: {
        //   partial_payment: {
        //     allow_partial_payment: true,
        //     minimum_amount_due: {
        //       currency_code: "USD",
        //       value: "20.00"
        //     }
        //   },
        //   allow_tip: true,
        //   tax_calculated_after_discount: true,
        //   tax_inclusive: false,
        //   template_id: "TEMP-19V05281TU309413B"
        // },
        amount: {
            currency_code: 'EUR',
            value: 100,
            breakdown: {
                // custom: {
                //     label: "Packing Charges",
                //     amount: {
                //         currency_code: "USD",
                //         value: "10.00"
                //     }
                // },
                shipping: {
                    amount: {
                        currency_code: "USD",
                        value: "10.00"
                    },
                    //   tax: {
                    //     name: "Sales Tax",
                    //     percent: "7.25"
                    //   }
                },
                item_total: {
                    currency_code: "USD",
                    value: "10.00"
                }, tax_total: {
                    currency_code: "USD",
                    value: "10.00"
                },
                // discount: {
                //   invoice_discount: {
                //     percent: "5"
                //   }
                // }
            }
        }
    }

}