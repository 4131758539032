export class Card {
    _id: string = '';
    url: string = '';
    nftUrl: string = '';
    nftPreviewUrl: string = '';
    category: string | undefined;
    artistName: string | undefined;
    artistId: string | undefined;
    tokenIds: Array<string> = [];
    tokenLast: string | undefined;
    itemId: string | undefined;
    artwork: string | undefined;
    descriptionEN: string | undefined;
    descriptionFR: string | undefined;
    currency: string = '€';
    price: number = 0;
    cryptoValue: number | undefined;
    quantity: number = 0;
    selected: boolean | undefined;
    password: string | undefined;
    popularity: number | undefined;
    currentBid: boolean | undefined;
    status: 'available'|'unavailable'|'incoming'|'soldout' = 'available';
    creationDate: Date = new Date();
    modificationDate: Date = new Date();
    active: boolean | undefined;
}