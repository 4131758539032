import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { RampInstantSDK } from '@ramp-network/ramp-instant-sdk';
import { LoginComponent } from '../login/login.component';
import { MetamaskConnectComponent } from '../metamask-connect/metamask-connect.component';
import { Artist } from '../models/Artist';
import { Card } from '../models/Card';
import { User } from '../models/User';
import { PlayerComponent } from '../player/player.component';
import { ArtistService } from '../services/artist.service';
import { AuthService } from '../services/auth.service';
import { CardService } from '../services/card.service';
import { ContractService } from '../services/contract.service';
import { ConvertService } from '../services/convert.service';
import { MetamaskService } from '../services/metamask.service';
import { BuyPhysicalComponent } from './buy-physical/buy-physical.component';
import lgZoom from 'lightgallery/plugins/zoom';
import { BeforeSlideDetail } from 'lightgallery/lg-events';

@Component({
  selector: 'app-artist-page',
  templateUrl: './artist-page.component.html',
  styleUrls: ['./artist-page.component.scss']
})
export class ArtistPageComponent implements OnInit {
  artist: Artist | undefined;
  artworks: Card[] = [];
  selectedArtwork: Card | undefined;
  lang: string | undefined;
  load: boolean = false;
  transactionState: number = 0;
  priceMargin = 10; // %
  buyError: boolean = false;
  buyErrorMessage!: string;
  user: User | undefined;
  connectedToMetamask = false;
  fromComputer: boolean;
  dialogCfg: MatDialogConfig = new MatDialogConfig();
  maxDescLength: number = 600;
  pwLoading = false;
  downloadableIds: any = [];

  settings = {
    counter: false,
    plugins: [lgZoom],
  };
  onBeforeSlide = (detail: BeforeSlideDetail): void => {
    const { prevIndex, index } = detail;
    console.log(prevIndex, index);
  };

  constructor(private artistService: ArtistService,
    private router: Router,
    private http: HttpClient,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private metamaskService: MetamaskService,
    private contractService: ContractService,
    private authService: AuthService,
    private convertService: ConvertService,
    private cardService: CardService) {
    this.fromComputer = !/Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent);
    this.translateService.onLangChange.subscribe((lang: LangChangeEvent) => this.lang = lang.lang);
    this.convertService.getConvertedPrice();
    this.metamaskService.providerExists().then(() => this.connectedToMetamask = true).catch(() => this.connectedToMetamask = false);
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.lang = this.translateService.getDefaultLang();
    this.setArtist();
    this.setUser();
    this.setDialogConfig('left');
    this.setPriceMargin();
  }

  setUser() {
    this.authService.getCurrentUser().then((user: any) => {
      this.user = user;
      if (this.user?.currentPurchaseUrl) {
        let str = this.user?.currentPurchaseUrl;
        let id = str.substring(str.lastIndexOf('/') + 1, str.lastIndexOf('?'));
        let secret = str.substring(str.lastIndexOf('=') + 1);
        this.http.get('https://api-instant.ramp.network/api/host-api/purchase/' + id + '?secret=' + secret).subscribe((res: any) => {
          if (['RELEASING', 'RELEASED', 'EXPIRED', 'CANCELLED'].includes(res.status)) {
            this.removePurchaseUrl(this.user);
          }
        }, (err) => {
          if (err.error.code == 'DATA.NOT_FOUND') {
            this.removePurchaseUrl(this.user);
          }
        })
      }
    })
  }

  setPriceMargin() {
    this.authService.getAdminInfos('y').then((infos: any) => {
      this.priceMargin = infos.marginLimit;
    });
  }

  removePurchaseUrl(user: User | undefined) {
    if (user) {
      user.currentPurchaseUrl = undefined;
      this.authService.updateUser(user).then(() => window.location.reload());
    }
  }

  setDialogConfig(side?: string) {
    let cfg = new MatDialogConfig();
    cfg.width = '320px';
    cfg.height = '100vh';
    cfg.position = side == 'right' ? { right: '0', bottom: '0' } : { left: '0', bottom: '0' };
    this.dialogCfg = cfg;
  }

  // Get and set the artist from its id (included in the url)
  setArtist() {
    this.artistService.getArtistById(window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)).then((data: any) => {
      let artist = new Artist();
      artist._id = data._id;
      artist.category = data.category;
      artist.descriptionEN = data.descriptionEN;
      artist.descriptionFR = data.descriptionFR;
      artist.name = data.name;
      artist.modificationDate = data.modificationDate;
      artist.creationDate = data.creationDate;
      artist.active = data.active;
      artist.url = data.url;
      this.artist = artist;
      this.setArtistCards(this.artist);
    });
  }

  // Get and set the cards associated to one artist
  setArtistCards(artist: Artist) {
    this.cardService.getCardsFromArtistId(artist._id).then((data: any) => {
      let tab: Card[] = data.reverse();
      this.setArtworks(tab);

      this.selectedArtwork = this.artworks[0];
    })
  }

  // Save the loaded cards into this.artworks
  setArtworks(data: any) {
    this.artworks = [];
    for (let artwork of data) {
      let card = new Card();
      card._id = artwork._id;
      card.category = artwork.category;
      card.descriptionEN = artwork.descriptionEN;
      card.descriptionFR = artwork.descriptionFR;
      card.artwork = artwork.artwork;
      card.artistId = artwork.artistId;
      card.itemId = artwork.itemId;
      card.tokenIds = artwork.tokenIds;
      card.artistName = artwork.artistName;
      card.modificationDate = artwork.modificationDate;
      card.creationDate = artwork.creationDate;
      card.active = artwork.active;
      card.currency = artwork.currency;
      card.price = artwork.itemId && artwork.itemId != '0' ? this.convertService.eur2matic ? Math.round((artwork.cryptoValue * this.convertService.eur2matic) * 100) / 100 : artwork.price : artwork.price;

      if (artwork.password) card.password = artwork.password;
      // manage crypto price fluctuations within 10 percent of starting price;
      if (artwork.itemId && artwork.itemId != '0' && card.price && Math.abs(card.price - artwork.price) > (artwork.price * this.priceMargin / 100) && this.convertService.eur2matic) {
        card.price = artwork.price;
        artwork.cryptoValue = artwork.price / this.convertService.eur2matic;
        this.contractService.changePrice(artwork._id, artwork.cryptoValue);
        this.cardService.modifyArtworkWithFile(artwork._id, artwork, artwork.url);
      }
      // end
      card.cryptoValue = artwork.cryptoValue
      card.quantity = artwork.quantity;
      card.selected = artwork.selected;
      card.popularity = artwork.popularity;
      card.currentBid = artwork.currentBid;
      card.status = artwork.status;
      card.url = artwork.url;
      card.nftUrl = artwork.nftUrl;
      card.nftPreviewUrl = artwork.nftPreviewUrl;
      this.artworks?.push(card);
    }
    this.load = false;
  }

  isDownloadable(id: string) {
    return this.downloadableIds.includes(id);
  }

  isTooLong(desc: string | undefined) {
    return desc ? desc.length > 600 : false;
  }

  // Purchase with cryptos
  buy(artwork: Card) {
    if (this.connectedToMetamask) {
      if (!this.user) {
        const dialogRef = this.dialog.open(LoginComponent);
        dialogRef.updatePosition(this.dialogCfg?.position);
        dialogRef.updateSize(this.dialogCfg?.width, this.dialogCfg?.height);
      } else {
        if (this.user?.linkedWalletsAddresses) {
          if (this.user?.linkedWalletsAddresses.length > 0) {
            this.selectedArtwork = artwork;
            if (artwork._id && artwork.price && artwork.currency && this.user?._id) {
              this.transactionState = 0.1;
              this.contractService.transactionStateEmitter.subscribe((state) => this.transactionState = state);
              // console.log('userID', this.user?._id);
              // console.log('artwork._id', artwork._id);

              this.contractService.buy(artwork._id, this.user._id)
                .then(() => {
                  this.transactionState = 3;
                })
                .catch((error) => {
                  this.buyError = true;
                  // console.log(error);
                  if (error.data) {
                    switch (error.data.code) {
                      case 3:
                        this.buyErrorMessage = this.translateService.instant('Artist.PropertyIssue');
                        if (this.user)
                          this.contractService.bought(artwork, this.user._id, "")
                        break;
                      case -32000:
                        this.buyErrorMessage = this.translateService.instant('Artist.InsufficientFunds');
                        break;
                      default:
                        this.buyErrorMessage = error.data.message;
                    }
                  } else {
                    this.buyErrorMessage = error
                  }
                });
            }
          } else {
            this.dialog.open(MetamaskConnectComponent);
          }
        } else {
          this.dialog.open(MetamaskConnectComponent);
        }
      }
    } else {
      this.dialog.open(MetamaskConnectComponent);
    }
  }

  isOnlyNFT(artwork: Card) {
    return artwork.itemId ? artwork.itemId != '0' : false;
  }

  buyPhysical(artwork: Card) {
    this.dialog.open(BuyPhysicalComponent, { data: artwork });
  }

  buyArtwork(artworkId: string | undefined) {
    let card = this.artworks.filter((artwork) => artwork._id === artworkId)[0];
    if (this.user?.currentPurchaseUrl) {
      window.open(this.user.currentPurchaseUrl);
    } else {
      let wei = 1000000000000000000;
      let fee = 0.2; // 1/4 de MATIC;
      let cryptoValue = card?.cryptoValue ? card.cryptoValue : 200;
      let emailAddress = this.user?.email ? this.user.email : 'emailaddress@gmail.com';
      let userAddress = this.user?.linkedWalletsAddresses?.[0] ? this.user.linkedWalletsAddresses[0] : '0xYourWalletAdress';
      let rampSdk = new RampInstantSDK({
        hostAppName: 'NEOSATELLITE',
        hostLogoUrl: 'https://neosatellite.io/assets/icons/neosat-logo.png',
        variant: 'auto',
        selectedCountryCode: 'FR',
        defaultAsset: 'MATIC',
        swapAsset: 'MATIC_MATIC',
        swapAmount: ((cryptoValue + fee) * wei).toString(),
        userEmailAddress: emailAddress,
        userAddress: userAddress
      });
      rampSdk.show();
      rampSdk.on('*', (event) => {
        console.log(event);
        if (event.type == 'PURCHASE_CREATED' && this.user) {
          let purchaseUrl = 'https://transactions.ramp.network/#/details/';
          purchaseUrl += event.payload.purchase.id + '?secret=' + event.payload.purchaseViewToken;
          this.user.currentPurchaseUrl = purchaseUrl;
          this.authService.updateUser(this.user);
        }
      });
    }
  }

  checkPw(id: string, input: any) {
    console.log(input);
    this.pwLoading = true;
    this.cardService.checkArtworkPassword(id, input).then(() => {
      this.pwLoading = false;
      this.downloadableIds.push(id);
    }).catch((err) => {
      console.log('ERRRRURR', err); this.pwLoading = false;
    })

  }

  play(url: string) {
    this.dialog.open(PlayerComponent, { data: url });
  }

  backHome() {
    this.router.navigate(['home']);
  }
}

