import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BurgerMenuComponent } from '../footer/burger-menu/burger-menu.component';
import { LoginComponent } from '../login/login.component';
import { User } from '../models/User';
import { AuthService } from '../services/auth.service';
import { SearchService } from '../services/search.service';

class SearchResult {
  str: string | undefined;
  artistId: string | undefined;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  searchInput: FormControl;
  searchResults: SearchResult[] | undefined;
  searching = false;
  dialogCfg: any;
  user: User | undefined;

  constructor(private searchService: SearchService, private dialog: MatDialog, private authService: AuthService) {
    this.searchInput = new FormControl();
    this.setDialogConfig();
  }

  ngOnInit(): void {
      this.authService.getCurrentUser().then((user: any) => this.user = user)
  }

  setDialogConfig(side?: string) {
    let cfg = new MatDialogConfig();
    cfg.width = '320px';
    cfg.height = '100vh';
    cfg.position = side == 'right' ? { right: '0', bottom: '0'} : { left: '0', bottom: '0'} ;
    this.dialogCfg = cfg;
  }

  searchFor() {
    if (this.searchInput.value) {
      this.searchService.search(this.searchInput.value).then(
        (data: any) => {
          data.length ? this.searchResults = data : this.searchResults = undefined;
        }).catch((error) => {
          console.log(error);
        })
    } else {
      this.searchResults = undefined
    }
  }

  selectLink() {
    this.searchInput.setValue('');
    this.searchResults = undefined;
  }

  focusOut() {
    setTimeout(() => {
      this.searchResults = undefined;
      this.searching = false;
    }, 100)
  }

  openLogin(): void {
    this.setDialogConfig();
    const dialogRef = this.dialog.open(LoginComponent);

    dialogRef.updatePosition(this.dialogCfg?.position);
    dialogRef.updateSize(this.dialogCfg?.width, this.dialogCfg?.height);
  }

  openMenu() {
    this.setDialogConfig('right');
    const dialogRef = this.dialog.open(BurgerMenuComponent);

    dialogRef.updatePosition(this.dialogCfg?.position);
    dialogRef.updateSize(this.dialogCfg?.width, this.dialogCfg?.height);
  }
}
