import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Card } from 'src/app/models/Card';
import { ArtistService } from 'src/app/services/artist.service';
import { AuthService } from 'src/app/services/auth.service';
import { CardService } from 'src/app/services/card.service';
import { UrlService } from 'src/app/services/url.service';
import { ethers } from 'ethers';
import detectEthereumProvider from '@metamask/detect-provider';
import Market from 'src/artifacts/contracts/Market.sol/NFTMarket.json';
import { ConvertService } from 'src/app/services/convert.service';

export class ReducedArtist {
  id: string | undefined;
  name: string | undefined;
}
@Component({
  selector: 'app-add-artwork',
  templateUrl: './add-artwork.component.html',
  styleUrls: ['./add-artwork.component.scss']
})
export class AddArtworkComponent implements OnInit {
  genres: string[] = [];
  createForm: FormGroup;
  imagePreview: string | undefined;
  isAuth: boolean = false;
  artists: ReducedArtist[] = [];
  displayForm: boolean = false;
  transactionState: number = 0;
  transactionError: any;

  mdp = '';

  get hasImg() {
    return this.createForm.get('image')?.valid && this.createForm.get('image')?.value
  }

  get hasFile() {
    return this.createForm.get('file')?.valid && this.createForm.get('file')?.value
  }

  get hasPrev() {
    return this.createForm.get('previewFile')?.valid && this.createForm.get('previewFile')?.value
  }

  get isToGive() {
    return this.createForm.get('isNFT')?.value == 2
  }

  constructor(private auth: AuthService,
    private router: Router,
    private urls: UrlService,
    private artistService: ArtistService,
    private convertService: ConvertService,
    private cardService: CardService) {
    this.convertService.getConvertedPrice();
    if (this.auth.user?.profile == "admin") {
      this.isAuth = true;
    } else {
      this.router.navigate(['**'])
    }
    this.createForm = new FormGroup({
      name: new FormControl('', Validators.minLength(1)),
      isNFT: new FormControl('', Validators.required),
      artist: new FormControl('', Validators.required),
      image: new FormControl('', Validators.required),
      file: new FormControl(''),
      previewFile: new FormControl(''),
      descriptionEN: new FormControl(''),
      descriptionFR: new FormControl(''),
      category: new FormControl('', Validators.required),
      currency: new FormControl('', Validators.required),
      price: new FormControl('', [Validators.required, Validators.min(1)]),
      quantity: new FormControl('', [Validators.required, Validators.min(-1)]),
      selected: new FormControl('', Validators.required),
      popularity: new FormControl('', [Validators.required, Validators.min(0), Validators.max(1)]),
      currentBid: new FormControl('', Validators.required),
      status: new FormControl('', Validators.required)
    });
  }

  ngOnInit(): void {
    this.genres = this.urls.getGenres();
    this.artistService.getAllArtists().then((artists: any) => this.artists = artists);
  }

  setPricing(artwork: Card): string {
    var pricing: number = 0
    artwork.price ? pricing = artwork.price : pricing = 1;
    artwork.currency === "$" ? pricing = pricing * 0.4254 : 0;
    artwork.currency === "€" ? pricing = pricing * 0.4796 : 0;
    return pricing.toString()
  }

  artistSelected() {
    this.displayForm = true;
  }

  // contract.createToken(artwork.url).then((ttransaction: any) => {
  //   this.transactionState = 4;
  //   ttransaction.wait().then((tx: any) => {
  //     let event = tx.events[0];
  //     let value = event.args[2];
  //     let tokenIde = value.toNumber();
  //     var pricing: string = this.setPricing(artwork);
  //     const price = ethers.utils.parseUnits(pricing, 'ether');

  transactArtwork(providor: ethers.providers.Web3Provider, artwork: Card) {
    return new Promise((resolve) => {
      const nftmarketaddress = "0xEF6028b8D0EC16165CEDb3E9ba441297a0198Ddd"
      const nftaddress = "0xD4eE60185333cA2F25fC0AD8840B6E61D4b2708D"
      const signer = providor.getSigner()
      let contract = new ethers.Contract(nftmarketaddress, Market.abi, signer);
      this.transactionState = 5;
      var pricing: string = this.setPricing(artwork);
      const price = ethers.utils.parseUnits(pricing, 'ether');
      // contract.getListingPrice().then((listingPrice: any) => {
      // let listingPrices = listingPrice.toString();
      // console.log(listingPrice);
      contract.createMarketItem(nftaddress, price, artwork.quantity).then((transaction: any) => {
        // console.log('transaction', transaction);
        transaction.wait().then((tx: any) => {
          // console.log('tx', tx);
          this.transactionState = 6;
          artwork.itemId = tx.events[0].args['itemId'].toString();
          resolve(artwork);
        });
      }).catch(() => {
        const id = artwork._id + "";
        this.cardService.deleteCard(id);
      });
      // });
    });
  }

  createArtwork() {
    let isNFT = this.createForm.get('isNFT')?.value;

    if (!isNFT || isNFT == 2) {
      this.createPhysicalArtwork()
    } else {
      try {
        this.transactionState = 1;
        detectEthereumProvider().then((provider: any) => {
          this.transactionState = 2;
          provider.enable().then(() => {
            this.transactionState = 3;
            const providor = new ethers.providers.Web3Provider(provider);
            let artwork = this.setArtwork();
            this.cardService.createNewArtworkWithFile(artwork, [this.createForm.get('image')?.value, this.createForm.get('file')?.value, this.createForm.get('previewFile')?.value]).then((saved: any) => {
              let url = saved.url + '';
              let id = saved._id + '';
              this.transactArtwork(providor, saved).then((artworkk: any) => {
                this.transactionState = 9;
                this.cardService.modifyArtworkWithFile(id, artworkk, url, -1);
                this.createForm.reset();
              }).catch((error) => {
                confirm(error.error.error.message);
              });
            }).catch(() => console.log('Transaction has failed'));
          }).catch(() => console.log('Metamask connection failed'));
        }).catch((error) => {
          console.log('Provider error: ' + error);
          this.transactionError = error;
        });
      } catch (err) {
        this.transactionError = err;
      }
    }


  }

  createPhysicalArtwork() {
    let artwork = this.setArtwork();
    this.cardService.createNewArtworkWithFile(artwork, [this.createForm.get('image')?.value, this.createForm.get('file')?.value, this.createForm.get('previewFile')?.value]).then((saved: any) => {
      console.log(saved);
      alert('Oeuvre physique bien créée !');
      this.router.navigate(['/admin'])
    }, (err) => {
      console.log(err);
    })
  }

  setArtwork(): Card {
    let artwork = new Card();
    artwork.artwork = this.createForm.get('name')?.value;
    artwork.descriptionEN = this.createForm.get('descriptionEN')?.value;
    artwork.descriptionFR = this.createForm.get('descriptionFR')?.value;
    artwork.category = this.createForm.get('category')?.value;
    artwork.artistId = this.createForm.get('artist')?.value;
    artwork.currency = this.createForm.get('currency')?.value;
    artwork.price = this.createForm.get('price')?.value;
    // SET CRYPTO VALUE
    if (artwork.currency == '€' && artwork.price && this.convertService.eur2matic) {
      artwork.cryptoValue = artwork.price / this.convertService.eur2matic;
    } else if (artwork.currency == '$' && artwork.price && this.convertService.usd2matic) {
      artwork.cryptoValue = artwork.price / this.convertService.usd2matic;
    } else {
      artwork.cryptoValue = artwork.price;
    }

    artwork.quantity = this.createForm.get('quantity')?.value;
    artwork.tokenIds = []; // Gonna add the new tokenId(s) to the existing tokenIds array when created
    this.createForm.get('isNFT')?.value ? artwork.tokenLast = "" : artwork.tokenLast = undefined;
    artwork.selected = this.createForm.get('selected')?.value;
    artwork.popularity = this.createForm.get('popularity')?.value;
    artwork.currentBid = this.createForm.get('currentBid')?.value;
    artwork.status = this.createForm.get('status')?.value;
    artwork.url = '';
    artwork.artistName = this.artists.find(a => a.id == artwork.artistId)?.name;

    if (this.createForm.get('isNFT')?.value == 2) {
      artwork.password = this.mdp;
    }

    return artwork;
  }

  onImagePick(event: Event) {
    const htmlElement = (event.target as HTMLInputElement);
    if (htmlElement.files) {
      const file = htmlElement.files[0];
      this.createForm.get('image')?.patchValue(file);
      this.createForm.get('image')?.updateValueAndValidity();
      const reader = new FileReader();
      reader.onload = () => {
        if (this.createForm.get('image')?.valid) {
          this.imagePreview = reader.result as string;
        } else {
          this.imagePreview = undefined;
        }
      };
      reader.readAsDataURL(file);
    }
  }

  onFilePick(event: Event) {
    const htmlElement = (event.target as HTMLInputElement);
    if (htmlElement.files) {
      const file = htmlElement.files[0];
      this.createForm.get('file')?.patchValue(file);
      this.createForm.get('file')?.updateValueAndValidity();
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log('Fichier NFT bien ajouté !');
      };
      reader.readAsDataURL(file);
    }
  }

  onPreviewFilePick(event: Event) {
    const htmlElement = (event.target as HTMLInputElement);
    if (htmlElement.files) {
      const file = htmlElement.files[0];
      this.createForm.get('previewFile')?.patchValue(file);
      this.createForm.get('previewFile')?.updateValueAndValidity();
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log('Fichier Preview bien ajouté !');
      };
      reader.readAsDataURL(file);
    }
  }

  backToAdmin() {
    this.router.navigate(['admin']);
  }
}
