import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ethers } from 'ethers';
import detectEthereumProvider from '@metamask/detect-provider';
import Market from 'src/artifacts/contracts/Market.sol/NFTMarket.json';
import NFT from 'src/artifacts/contracts/NFT.sol/NFT.json';
import { CardService } from './card.service';
import { Card } from '../models/Card';
import { AuthService } from './auth.service';

class MetaData {
  description?: string;
  external_url?: string;
  image?: string;
  image_data?: string;
  animation_url?: string;
  youtube_url?: string;
  name?: string;
  attributes?: {display_type: string,trait_type: string, value: any};
  background_color?: string; // hexadecimal sans #
}

@Injectable({
  providedIn: 'root'
})
export class ContractService {
  nftmarketaddress = "0xEF6028b8D0EC16165CEDb3E9ba441297a0198Ddd";
  nftaddress = "0xD4eE60185333cA2F25fC0AD8840B6E61D4b2708D";
  @Output() transactionStateEmitter: EventEmitter<number> = new EventEmitter();

  userId: string = '';
  constructor(private http: HttpClient, private cardService: CardService, private authService: AuthService) {
    this.authService.getCurrentUser().then((user: any) => {
      this.userId = user?._id;
    })
  }

  createToken(url: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      let wallet = new ethers.Wallet("16c742a5a44f195c7c8e07467748e657ba1a4a1a33487a3ef422b7eec437aa09");
      const provider = new ethers.providers.JsonRpcProvider("https://polygon-mainnet.infura.io/v3/c894882da7a244ba89b606920636a3a0");
      let walletSigner = wallet.connect(provider) ;
      // let contract = new ethers.Contract(this.nftaddress, NFT.abi, provider);
      let contract = new ethers.Contract(this.nftaddress, NFT.abi, walletSigner);
      let maxFeePerGas = ethers.BigNumber.from(40000000000) // fallback to 40 gwei
      let maxPriorityFeePerGas = ethers.BigNumber.from(40000000000) // fallback to 40 gwei
      this.http.get('https://gasstation-mainnet.matic.network/v2').subscribe((data : any) => {
        maxFeePerGas = ethers.utils.parseUnits(Math.ceil(data.fast.maxFee) + '', 'gwei')
        maxPriorityFeePerGas = ethers.utils.parseUnits(Math.ceil(data.fast.maxPriorityFee) + '', 'gwei')
        contract.estimateGas.createToken(url).then((estimatedGas: ethers.BigNumber) => {
          contract.createToken(url, {
            maxFeePerGas: maxFeePerGas,
            maxPriorityFeePerGas: maxPriorityFeePerGas,
            gasLimit: estimatedGas
          }).then((ttransaction: any) => {
            console.log('ttransaction', ttransaction);
            ttransaction.wait().then((tx: any) => {
            console.log('tx', tx);
          
            let event = tx.events[0];
            let value = event.args[2];
            let tokenIde = value.toString();

            console.log('tokenIde', tokenIde);
          
            resolve(tokenIde);
          }).catch(() => {
            console.log('Contract en erreur');
          
            reject("error");
          });
        }).catch(() => {
          console.log('Fonction en erreur');
        
          reject("error");
        });
      }).catch(() => {
        console.log('Fonction en erreur');
      
        reject("error");
      }); 
    }, () => {
      console.log('Fonction en erreur');
    
      reject("error");
    });
    });
  }

  isCreated(cardId: string): Promise<Card> {
    return new Promise<Card>((resolve, reject) => {
      this.cardService.getCardById(cardId).then((card: Card) => {
        if (typeof card.tokenLast != "undefined" && card.tokenLast != "") {
          resolve(card);
        }
        else {
          let metadata: MetaData = {
            description: card.descriptionEN,
            external_url: `https://neosatellite.io/artist/${card.artistId}`,
            image: this.isVideo(card.nftUrl) ? card.url : card.nftUrl,
            name: card.artwork
          };
          if (this.isVideo(card.nftUrl)) {
            metadata.animation_url = card.nftUrl
          }

          this.createToken(JSON.stringify(metadata)).then((ret: any) => {
            card.tokenLast = ret;
            resolve(card);
          }).catch(() => {
            reject("Couldn't create token");
          });
        }
      }).catch(() => {
        reject("Cannot find card");
      });
    });
  }

  isVideo(url: string) {
    let arr = url.split('.');
    let ext = arr[arr.length-1];
    let videoExt = ['GLTF', 'GLB', 'WEBM', 'MP4', 'M4V', 'OGV', 'OGG'];
    return videoExt.includes(ext.toUpperCase());
  }

  buyOnMobile(cardId: string, userId: string) {
    return new Promise((resolve, reject) => {
      // CREATE TRANSACTION
      const contract = new ethers.Contract(this.nftmarketaddress, Market.abi);
      this.isCreated(cardId).then((card: Card) => {
        card.tokenLast ? 0 : card.tokenLast = '';
        card.itemId ? 0 : card.itemId = '';
        card.cryptoValue ? 0 : card.cryptoValue = 0;
        const pricing = ethers.utils.parseUnits(card.cryptoValue.toString(), 'ether');
        console.log('card', card);
        console.log('pricing', pricing);

        contract.createMarketSale(this.nftaddress, parseInt(card.tokenLast), parseInt(card.itemId), {
          value: pricing
        }).then((transaction: any) => {
          transaction.wait();
          console.log('transaction', transaction);
          resolve(transaction)

        }).catch((err: any) => {
          console.log('Erreur avec la fonction createMarketSale du contrat');

          reject(err);
        })
      }).catch((err) => {
        console.log('Erreur avec isCreated');

        reject(err);
      })

    });
  }

  // Purchase with crypto-currencies
  buy(cardId: string, userId: string) {
    return new Promise((resolve, reject) => {
      // // MOBILE BROWSER
      // if (/Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent)) {
      //   console.log('Buy with Mobile');
        
      //   this.buyOnMobile(cardId, userId)
      //     .then(() => resolve(1))
      //     .catch((err) => reject(err))
      // } else {
      //   console.log('buy on computer');
        
        // COMPUTER BROWSER
        detectEthereumProvider().then((provider: any) => {
          this.transactionStateEmitter.emit(1);
          console.log('Metamask detected');
          provider.enable().then(() => {
            console.log('Metamask enabled');
            this.transactionStateEmitter.emit(2);
            const providor = new ethers.providers.Web3Provider(provider);
            const signer = providor.getSigner();
            const contract = new ethers.Contract(this.nftmarketaddress, Market.abi, signer);
            this.isCreated(cardId).then((card: Card) => {
              card.tokenLast ? 0 : card.tokenLast = '';
              card.itemId ? 0 : card.itemId = '';
              card.cryptoValue ? 0 : card.cryptoValue = 0;
              // console.log('card', card);
              const pricing = ethers.utils.parseUnits(card.cryptoValue.toString(), 'ether');
              contract.createMarketSale(this.nftaddress, parseInt(card.tokenLast), parseInt(card.itemId), {
                value: pricing
              }).then((transaction: any) => {
                transaction.wait();
                this.bought(card, userId).then((response) => {
                  resolve(response);
                }).catch((error) => {
                  reject(error);
                });
              }).catch((err: any) => {
                if (card._id && card.url)
                  this.cardService.modifyArtworkWithFile(card._id, card, card.url, -1).then(() => {
                    // console.log('Pourquoi ?', card);
                  })
                reject(err);
              });
            }).catch((error) => {
              reject(error);
            });;
          }).catch(() => {
            reject('Not connected to Metamask. Please make sure that Metamask is installed on your browser.')
          });
        }).catch((error) => {
          reject(error)
        });
      // }
    });
  }

  // To modify the status of a sold artwork
  bought(card: Card, userId: string, payId?: string) {
    let token = window.sessionStorage.getItem('authToken');
    return new Promise((resolve, reject) => {
      this.http.put(environment.protocol + '://' + environment.path + '/api/artwork/buy/' + userId + '/', { card: card, payId: payId }, {
        headers: {
            Authorization: token ? token : 'none',
            UserID: this.userId
        }
    }).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  // Get the artwork related to 'tokenId'
  getOneNst(itemId: string) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.protocol + '://' + environment.path + '/api/artwork/' + itemId + '/nst').subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  // Get the artworks related to the connected wallet
  async getMyNst() {
    let provider: any = await detectEthereumProvider()
    await provider.enable()
    const providor = new ethers.providers.Web3Provider(provider)
    const signer = providor.getSigner();

    const marketContract = new ethers.Contract(this.nftmarketaddress, Market.abi, signer);
    const tokenContract = new ethers.Contract(this.nftaddress, NFT.abi, providor);
    let data: any = await marketContract.fetchMyNFTs()

    return Promise.all(data.map(async (i: any) => {
      let tokenUri = await tokenContract.tokenURI(i.tokenId)
      let tokenId = i.tokenId + '';
      let item: any = this.getOneNst(tokenId)
      item ? item.url = tokenUri : 0;
      return item;
    }));
  }

  async changePrice(cardId: string, price: number) {
    return new Promise((resolve, reject) => {
      let wallet = new ethers.Wallet("16c742a5a44f195c7c8e07467748e657ba1a4a1a33487a3ef422b7eec437aa09");
      const provider = new ethers.providers.JsonRpcProvider("https://polygon-mainnet.infura.io/v3/c894882da7a244ba89b606920636a3a0");
      let walletSigner = wallet.connect(provider);
      const pricing = ethers.utils.parseUnits(price.toString(), 'ether');
      this.isCreated(cardId).then((card: Card) => {
        let contract = new ethers.Contract(this.nftmarketaddress, Market.abi, walletSigner)
        let maxFeePerGas = ethers.BigNumber.from(40000000000) // fallback to 40 gwei
        let maxPriorityFeePerGas = ethers.BigNumber.from(40000000000) // fallback to 40 gwei
        this.http.get('https://gasstation-mainnet.matic.network/v2').subscribe((data : any) => {
          maxFeePerGas = ethers.utils.parseUnits(Math.ceil(data.fast.maxFee) + '', 'gwei')
          maxPriorityFeePerGas = ethers.utils.parseUnits(Math.ceil(data.fast.maxPriorityFee) + '', 'gwei')
          typeof card.tokenLast == "undefined" ? card.tokenLast = '' : 0;
          typeof card.itemId == "undefined" ? card.itemId = '' : 0;
          contract.estimateGas.updateMarketItem(this.nftaddress, parseInt(card.itemId), pricing).then((estimatedGas: ethers.BigNumber) => {
            typeof card.itemId == "undefined" ? card.itemId = '' : 0;
            contract.updateMarketItem(this.nftaddress, parseInt(card.itemId), pricing, {
              gasLimit: estimatedGas.mul(1.1)
          }).then((transaction: any) => {
            transaction.wait();
            resolve(true);
          }).catch(() => {
            reject('Une erreur s\'est produite pendant l\'acquisition de votre NFT. Contactez nous.');
          });
        }).catch(() => {
          reject('Une erreur s\'est produite pendant l\'acquisition de votre NFT. Contactez nous.');
        });
      });
      }).catch((error) => {
        reject(error);
      });
    });
  }
}
