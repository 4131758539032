import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-edit-wallet',
  templateUrl: './edit-wallet.component.html',
  styleUrls: ['./edit-wallet.component.scss']
})
export class EditWalletComponent implements OnInit {
  user: User | undefined;
  walletForm: FormGroup;
  success: boolean = false;
  error: string | undefined;

  constructor(private authService: AuthService, private dialog: MatDialog, private translateService: TranslateService) {
    this.walletForm = new FormGroup({
      current: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(50)])
    })
  }

  ngOnInit(): void {
    this.authService.getCurrentUser().then((user: any) => {
      this.user = user;
    });

    this.walletForm.valueChanges.subscribe(() => {
      this.error = undefined;
    })
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  editWallet() {
    if (this.user?._id && (this.user?.linkedWalletsAddresses != undefined))
    this.authService.editWallets(this.user?._id, this.walletForm?.get('current')?.value, this.user?.linkedWalletsAddresses)
      .then((data) => {
        if (data) {
          this.success = true;
        }
      })
      .catch((error) => {
        if (error.error == -1) this.error = this.translateService.instant('Profile.WrongPw');
        if (error.error == -2) this.error = this.translateService.instant('Profile.ServerErr');
      })
  }

  delete(address: string) {
    if (this.user)
      this.user.linkedWalletsAddresses = this.user?.linkedWalletsAddresses?.filter((str) => str != address);
  }
}
