import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Artist } from '../models/Artist';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class ArtistService {

    userId: string = '';

    constructor(private http: HttpClient, private authService: AuthService) {
        this.authService.getCurrentUser().then((user: any) => {
            this.userId = user._id;
        })
    }

    // Return all artists in DB (full object)
    getArtists() {
        return new Promise((resolve, reject) => {
            this.http.get(environment.protocol + '://' + environment.path + '/api/artist').subscribe(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        })
    }

    // Return all artists in DB (id, name)
    getAllArtists() {
        return new Promise((resolve, reject) => {
            this.http.get(environment.protocol + '://' + environment.path + '/api/artist/all').subscribe(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        })
    }

    getArtistById(id: string) {
        return new Promise((resolve, reject) => {
            this.http.get(environment.protocol + '://' + environment.path + '/api/artist/' + id).subscribe(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    // Create a new artist with its associated picture
    createNewArtistWithFile(artist: Artist, image: Array<File>) {
        let token = window.sessionStorage.getItem('authToken');
        return new Promise((resolve, reject) => {
            const artistData = new FormData();
            artistData.append('artist', JSON.stringify(artist));
            artistData.append('myFiles', image[0], artist.name);
            this.http.post(environment.protocol + '://' + environment.path + '/api/artist', artistData, {
                headers: {
                    UserID: this.userId,
                    Authorization: token ? token : 'none'
                }
            }).subscribe(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    // Modify an artist in DB
    modifyArtistWithFile(id: string, artist: Artist, image: Array<File> | string) {
        let token = window.sessionStorage.getItem('authToken');
        return new Promise((resolve, reject) => {
            let artistData: Artist | FormData;
            if (typeof image === 'string') {    // without new picture
                artist.url = image;
                artistData = artist;
            } else {                            // with new picture
                artistData = new FormData();
                artistData.append('artist', JSON.stringify(artist));
                artistData.append('myFiles', image[0], artist.name);
            }
            this.http.put(environment.protocol + '://' + environment.path + '/api/artist/' + id, artistData, {
                headers: {
                    UserID: this.userId,
                    Authorization: token ? token : 'none'
                }
            }).subscribe(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    deleteArtist(id: string) {
        let token = window.sessionStorage.getItem('authToken');
        return new Promise((resolve, reject) => {
            this.http.delete(environment.protocol + '://' + environment.path + '/api/artist/' + id, {
                headers: {
                    UserID: this.userId,
                    Authorization: token ? token : 'none'
                }
            }).subscribe(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }
}
