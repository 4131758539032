import { ResourceLoader } from '@angular/compiler';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  langList: string[] = ['en', 'fr', 'ch']; // 'ru'
  access: boolean = false;

  constructor(private translate: TranslateService, private authService: AuthService, private loader: ResourceLoader) {
    this.loadTranslations();
    this.loadLangIcons();
    // this.authService.getCurrentUser().then(() => {
    //   this.access = this.authService.user != undefined;
    // })
  }

  loadTranslations() {
    let lang = this.translate.getBrowserLang();
    lang && this.langList.includes(lang) ? this.translate.setDefaultLang(lang) : this.translate.setDefaultLang('en');
    this.translate.addLangs(this.langList);
  }

  loadLangIcons() {
    this.langList.forEach((lang) => this.loader.get('../assets/flags/'+lang+'.png'));
  }

  haveAccess(event:any) {
    if (event===true) {
      this.access = true;
    }
  }
}
