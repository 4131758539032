import { Component, OnInit } from '@angular/core';
import { Artist } from '../models/Artist';
import { ArtistService } from '../services/artist.service';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
  artists: Artist[] | undefined;
  displayedArtists: Artist[] | undefined;
  lastHovered: number = -1;
  imgWidth: number = 0;
  imgHeight: number = 0;
  loadedArray: number[] = [];
  lastElementWidth: number | undefined;
  adaptedFontSize: number | undefined;

  constructor(private artistService: ArtistService) { }

  ngOnInit(): void {
    this.getArtists();
  }

  getArtists() {
    // if (!/Android|webOS|iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent)) {
      this.artistService.getArtists().then((artists: any) => {
        this.artists = artists;
        this.artists?.sort((a,b) => new Date(a.creationDate ? a.creationDate : '').getTime() - new Date(b.creationDate ? b.creationDate : '').getTime() )
        this.displayFor(window.innerWidth);
        window.addEventListener('resize', (win: any) => this.displayFor(win.target.innerWidth));
      })
    // }
  }

  displayFor(windowWidth: number) {
    let num = 0;
    if (windowWidth >= 992) {
      num = 11;
      this.adaptedFontSize = 3.5;
    } else if (windowWidth >= 576 && windowWidth < 992) {
      num = 9;
      this.adaptedFontSize = 3;
    } else {
      num = 7;
      this.adaptedFontSize = 2;
    }
    let array = [];
    while (this.artists && array.length < num && array.length < this.artists.length) {
      array.push(this.artists[array.length]);
    }
    this.displayedArtists = array;
  }

  isHover(event: any) {
    this.lastHovered = parseInt(event.target.id.substring(event.target.id.indexOf('-') + 1));
    setTimeout(() => {
      let width = document.getElementById(event.target.id)?.clientWidth;
      this.lastElementWidth = width;
    }, 1)
  }

  isOut() {
    this.lastHovered = -1;
  }

  isLoaded(i: number) {
    this.loadedArray.push(i);
  }

}
