import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Card } from '../models/Card';
import { User } from '../models/User';
import { AuthService } from '../services/auth.service';
import { CardService } from '../services/card.service';
import { ContractService } from '../services/contract.service';

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent implements OnInit {
  card: Card | undefined;
  user: User | undefined;
  approved = false;

  constructor(private cardService: CardService, private authService: AuthService, private contractService: ContractService, private router: Router) { }

  ngOnInit(): void {
    this.setUser()
    this.setCard();
  }

  setUser() {
    this.user = undefined;
    this.authService.getCurrentUser().then((user: any) => {
      if (user?.linkedWalletsAddresses.length > 0) {
        this.user = user;
      }
    });
  }

  setCard() {
    this.card = undefined;
    let id = window.location.pathname.substring(window.location.pathname.lastIndexOf('/'));
    this.cardService.getCardById(id)
      .then((card: any) => this.card = card)
      .catch((error) => console.log(error))
  }

  isApproved(bool: boolean) {
    if (bool && this.card?._id && this.user?._id) {
      this.approved = true
      // this.contractService.buyFiat(this.card._id, this.user._id)
      //   .then(() => {
      //     this.router.navigate(['/profile']);
      //     setTimeout(() => {
      //       window.location.reload();
      //     },50)
      //   })
      //   .catch((error) => console.log(error))
    } else if (!bool) {
      this.approved = false;
    }
  }

}
