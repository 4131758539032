
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Card } from 'src/app/models/Card';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth.service';
import { EmailService } from 'src/app/services/email.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { CardService } from 'src/app/services/card.service';

@Component({
  selector: 'app-buy-physical',
  templateUrl: './buy-physical.component.html',
  styleUrls: ['./buy-physical.component.scss']
})
export class BuyPhysicalComponent implements OnInit {
  loaded = true;
  bought = false;
  infos?: any;
  error?: string;
  user?: User;
  clientForm: FormGroup;
  existingMail = false;
  wrongLog = false;
  newUser = false;
  signupErr = false;
  incompletedStatus = false;
  CGUchecked = false;
  legalAgeChecked = false;
  deliveryFR = true;
  deliveryCost: number = 4.95;
  deliveryCostFR: number = 4.95;
  deliveryCostUE: number = 13.45;
  deliveryCostWW: number = 29.55;


  constructor(@Inject(MAT_DIALOG_DATA) public data: Card, private invoiceService: InvoiceService, private emailService: EmailService, private authService: AuthService, private cardService: CardService) {
    this.clientForm = new FormGroup({
      firstname: new FormControl('', Validators.required),
      lastname: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      // password: new FormControl('', Validators.minLength(6)),
      // password2: new FormControl('', Validators.minLength(6))
    })
  }

  ngOnInit(): void {
    // this.setUser();
  }

  // setUser() {
  //   this.authService.getCurrentUser().then((user: any) => {
  //     this.user = user;
  //     this.loaded = true;
  //   }).catch((err) => {
  //     this.loaded = true;
  //   })
  // }

  nextStep() {
    let user = new User();
    user.pseudo = `${this.clientForm.get('firstname')} ${this.clientForm.get('lastname')}`;
    user.email = `${this.clientForm.get('email')}`;
    this.user = user;
  }

  newClient() {
    this.authService.isMailPresent(this.clientForm.get('email')?.value)
      .then((res) => {
        // Email existant --> Se connecter
        this.existingMail = true;
      }).catch((err) => {
        console.log(err);
        // Create new user + connexion
        this.newUser = true;
      })

  }

  login() {
    this.authService.login(this.clientForm.get('email')?.value, this.clientForm.get('password')?.value)
      .then((res) => {
        // console.log(res)
        if (res == this.clientForm.get('email')?.value) {
          this.incompletedStatus = true;
        }
      })
      .catch((err) => {
        this.wrongLog = true;
      })
  }

  signup() {
    let pseudo = this.clientForm.get('firstname')?.value + this.clientForm.get('lastname')?.value;
    this.authService.createNewUser(pseudo, this.clientForm.get('email')?.value, this.clientForm.get('password')?.value, 'buyer')
      .then((res) => {
        this.incompletedStatus = true;
      })
      .catch((err) => {
        this.signupErr = true;
      })
  }

  deliver(zone:'FR'|'UE'|'WW') {
    this.deliveryCost = zone === 'FR' ? this.deliveryCostFR : zone === 'UE' ? this.deliveryCostUE : this.deliveryCostWW
  }

  approved(event: any) {
    this.infos = event;
    this.bought = true;
    this.savePurchase(this.infos[0].orderID);
    let invoiceInfos = this.infos[1];
    // console.log('this.infos', this.infos);
    this.invoiceService.createInvoice(invoiceInfos).then((res: any) => {
      console.log('Facture créée, envoyée et payée', res);
    }).catch((err) => {
      switch (err) {
        case 0: console.log('Erreur token');break
        case 1: console.log('Erreur creation');break
        // case 3: console.log('Erreur envoi');break
        // case 2: console.log('Erreur enregistrement paiement');break;
        default: console.log('Erreur lors de la creation/envoi/enregistrement de la facture');
      }
    })
  }

  savePurchase(purchaseId: string) {
    this.cardService.addPurchase(this.data._id, purchaseId).then((res) => {
      // console.log(res);
    }).catch((err) => {
        console.log(err)
    })
  }

  hasError(err: any) {
    this.error = err;
  }

}
