import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../models/User';
import { environment } from 'src/environments/environment';
import { EmailService } from './email.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public isAuth$ = new BehaviorSubject<boolean>(false);
  public user: User | undefined;
  private userId: string = '';

  constructor(private http: HttpClient, private emailService: EmailService) {
    this.getCurrentUser().then((user: any) => {
      this.user = user;
      this.userId = user._id;
    })
  }

  createNewUser(pseudo: string, email: string, password: string, profile: string) {
    pseudo = pseudo.toUpperCase();
    return new Promise((resolve, reject) => {
      this.http.post(
        environment.protocol + '://' + environment.path + '/api/auth/signup',
        { pseudo: pseudo, email: email.toLowerCase(), password: password, profile: profile })
        .subscribe(
          (data) => {
            this.login(email, password);
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  updateUser(user: User) {
    let token = window.sessionStorage.getItem('authToken');
    return new Promise((resolve, reject) => {
      this.http.put(
        environment.protocol + '://' + environment.path + '/api/auth/userUpdate', user, {
        headers: {
          UserID: this.userId,
          Authorization: token ? token : 'none'
        }
      })
        .subscribe(
          (data) => {
            resolve(data);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  login(email: string, password: string) {
    return new Promise((resolve, reject) => {
      this.http.post(
        environment.protocol + '://' + environment.path + '/api/auth/login',
        { email: email, password: password })
        .subscribe(
          (authData: any) => {
            this.setUser(authData);
            if (authData.status == 'incomplete') {
              // send confirmationLink
              this.emailService.sendConfirmLink(authData.email);
              resolve(authData.email)
            } else if (authData.status == 'complete') {
              this.isAuth$.next(true);
              if (this.user?.token && this.user?._id) {
                window.sessionStorage.setItem('authToken', this.user.token);
              }
              window.location.reload();
              resolve(authData);
            } else {
              this.emailService.sendConfirmLink(authData.email);
              resolve(authData.email)
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  // Set user's data from a backend response
  setUser(authData: any) {

    this.user = new User();
    this.user._id = authData._id ? authData._id : authData.userId;
    this.user.email = authData.email;
    this.user.token = authData.token;
    this.user.profile = authData.profile;
    this.user.pseudo = authData.pseudo;
    this.user.photoUrl = authData.photoUrl;
    this.user.purchasedTokenIds = authData.purchasedTokenIds;
    this.user.modificationDate = authData.modificationDate;
    this.user.creationDate = authData.creationDate;
    this.user.active = authData.active;
  }

  // Return user if lately connected (using session stored token)
  // This function is frequently used to get user's data
  getCurrentUser() {
    return new Promise((resolve, reject) => {
      if (window.sessionStorage.getItem('authToken')) {
        this.http.get(
          environment.protocol + '://' + environment.path + '/api/auth/token/' + window.sessionStorage.getItem('authToken'))
          .subscribe(
            (user: any) => {
              if (user) {
                this.setUser(user);
                this.isAuth$.next(true);
                user.password = undefined;
                resolve(user);
              } else {
                reject(0)
              }
            },
            (error) => {
              reject(error);
            }
          );
      } else {
        reject(undefined)
      }
    });
  }

  sendMailTo(email: string) {
    let body = {
      mailTo: email
    };
    return new Promise((resolve, reject) => {
      this.http.post(environment.protocol + '://' + environment.path + '/api/email/send', body).subscribe(
        (mailResponse: any) => {
          resolve(mailResponse);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  logout() {
    this.isAuth$.next(false);
    this.user = undefined;
    window.localStorage.clear();
    window.sessionStorage.clear();
    window.location.reload();
  }

  isMailPresent(mail: string) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.protocol + '://' + environment.path + '/api/email/ispresent/' + mail).subscribe(
        (res: any) => {
          resolve(res);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  editUserPicture(_id: string, image: File | string) {
    let token = window.sessionStorage.getItem('authToken');
    return new Promise((resolve, reject) => {
      let pictureData = new FormData();
      pictureData.append('myFiles', image, _id);
      this.http.put(environment.protocol + '://' + environment.path + '/api/auth/picture/' + _id, pictureData, {
        headers: {
          UserID: this.userId,
          Authorization: token ? token : 'none'
        }
      }).subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          console.log(error)
          reject(error);
        }
      );
    });
  }

  editPw(_id: string, currentPw: string, newPw: string) {
    let token = window.sessionStorage.getItem('authToken');
    return new Promise((resolve, reject) => {
      this.http.put(
        environment.protocol + '://' + environment.path + '/api/auth/editPw/' + _id,
        { current: currentPw, newPw: newPw }, {
        headers: {
          UserID: this.userId,
          Authorization: token ? token : 'none'
        }
      })
        .subscribe(
          (update) => {
            resolve(update);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
    });
  }

  editEmail(_id: string, currentPw: string, newEmail: string) {
    let token = window.sessionStorage.getItem('authToken');
    return new Promise((resolve, reject) => {
      this.http.put(
        environment.protocol + '://' + environment.path + '/api/auth/editEmail/' + _id,
        { current: currentPw, email: newEmail }, {
        headers: {
          UserID: this.userId,
          Authorization: token ? token : 'none'
        }
      })
        .subscribe(
          (update) => {
            resolve(update);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
    });
  }

  editWallets(_id: string, currentPw: string, newWallets: Array<string>) {
    let token = window.sessionStorage.getItem('authToken');
    return new Promise((resolve, reject) => {
      this.http.put(
        environment.protocol + '://' + environment.path + '/api/auth/editWallet/' + _id,
        { current: currentPw, linkedWalletsAddresses: newWallets }, {
        headers: {
          UserID: this.userId,
          Authorization: token ? token : 'none'
        }
      })
        .subscribe(
          (update) => {
            resolve(update);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
    });
  }

  changePassword(email: string, newPw: string) {
    let token = window.sessionStorage.getItem('authToken');
    return new Promise((resolve, reject) => {
      this.http.put(
        environment.protocol + '://' + environment.path + '/api/auth/recover',
        { email: email, password: newPw }, {
        headers: {
          UserID: this.userId,
          Authorization: token ? token : 'none'
        }
      })
        .subscribe(
          (update) => {
            resolve(update);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
    });
  }

  confirmAccount(email: string) {
    return new Promise((resolve, reject) => {
      this.http.put(
        environment.protocol + '://' + environment.path + '/api/auth/confirm',
        { email: email })
        .subscribe(
          (update) => {
            resolve(update);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
    });
  }

  getAdminInfos(id: string) {
    let token = window.sessionStorage.getItem('authToken');
    return new Promise((resolve, reject) => {
      this.http.get(
        environment.protocol + '://' + environment.path + '/api/auth/adminInfos/' + id, {
        headers: {
          Authorization: token ? token : 'none',
          UserID: this.userId
        }
      })
        .subscribe(
          (infos) => {
            resolve(infos);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
    });
  }

  updateAdminInfos(id: string, marginLimit: number) {
    let token = window.sessionStorage.getItem('authToken');
    return new Promise((resolve, reject) => {
      this.http.put(
        environment.protocol + '://' + environment.path + '/api/auth/adminInfos/' + id, { marginLimit: marginLimit }, {
        headers: {
          Authorization: token ? token : 'none',
          UserID: this.userId
        }
      })
        .subscribe(
          (infos) => {
            resolve(infos);
          },
          (error) => {
            console.log(error);
            reject(error);
          }
        );
    });
  }
}
