import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export class ArtworkInfo {
    cardId!: string;
    price!: string;
    currency!: string;
}


export class CardInfo {
    owner!: string;
    number!: string;
    expirationMonth!: string;
    expirationYear!: string;
    cvv!: string;
}

export class BillInfo {
    firstName!: string;
    lastName!: string;
    address1!: string;
    locality!: string;
    administrativeArea!: string;
    postalCode!: string;
    country!: string;
    email!: string;
    phoneNumber!: string;
}

@Injectable({
    providedIn: 'root'
})
export class FiatService {
    constructor(private http: HttpClient) {
    }

    // create(artworkId: string, userId: string) {
    //     return new Promise((resolve, reject) => {
    //         this.http.post(environment.protocol + '://' + environment.path + '/api/fiat', {artworkId: artworkId, userId: userId}).subscribe(
    //             (response) => {
    //                 resolve({ response, artworkId });
    //             },
    //             (error) => {
    //                 reject(error);
    //             }
    //         );
    //     });
    // }

    // buyFiat(artworkId: string, userId: string) {
    //     return new Promise((resolve, reject) => {
    //         this.create(artworkId, userId).then((data: any) => {
    //             resolve(data);
    //         }).catch((error) => {
    //             reject(error)
    //         });
    //     });
    // }

    getPaymentInfos(id: string) {
        return new Promise((resolve, reject) => {
            this.http.get(environment.protocol + '://' + environment.path + '/api/fiat/infos/' + id).subscribe(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        })
    }
}