import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EmailService {
    constructor(private http: HttpClient) { }

    // Save email from newsletter input
    saveEmail(email: string) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.protocol + '://' + environment.path + '/api/email', { email: email }).subscribe(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    // Return all emails in DB
    getEmails() {
        return new Promise((resolve, reject) => {
            this.http.get(environment.protocol + '://' + environment.path + '/api/email').subscribe(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    // Check if the temporary link is still valid
    isRecoveryLinkValid(id: string) {
        return new Promise((resolve, reject) => {
            this.http.get(environment.protocol + '://' + environment.path + '/api/email/recover' + id).subscribe(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    // Sending mail to confirm the freshly created account
    sendConfirmLink(email: string) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.protocol + '://' + environment.path + '/api/email/sendconfirmation', { mailTo: email }).subscribe(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    // Send the contact form to Neo Satellite
    sendContactMail(email: string, subject: string, content: string) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.protocol + '://' + environment.path + '/api/email/contact', { email: email, subject: subject, content: content }).subscribe(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }

    sendPurchaseConfirmationMail(email: string, link: string) {
        return new Promise((resolve, reject) => {
            this.http.post(environment.protocol + '://' + environment.path + '/api/email/purchase', {email, link}).subscribe(
                (response) => {
                    resolve(response);
                },
                (error) => {
                    reject(error);
                }
            );
        });
    }
}