import { Component, OnInit } from '@angular/core';
import { Card } from '../models/Card';
import { CardService } from '../services/card.service';
import { ConvertService } from '../services/convert.service';
import { ArtistService } from '../services/artist.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  releases: Card[] = [];
  hots: Card[] = [];
  ongoings: Card[] = [];
  selections: Card[] = [];
  incomings: Card[] = [];
  constructor(private cardService: CardService, private artistService: ArtistService, private convertService: ConvertService) { }

  ngOnInit(): void {
    this.fillCards();
    this.setCurrencies();
    window.scrollTo(0, 0);
  }

  setCurrencies() {
    this.convertService.getConvertedPrice();
  }

  fillCards(): void {
    this.artistService.getArtists().then((artists: any) => {
      let tab: Card[] = [];
      artists.forEach((artist: any) => {
        this.cardService.getCardsFromArtistId(artist._id).then((res: any) => {
          let card = new Card();
          card.url = res[0] ? res[0].url : artist.url;
          card.artistName = artist.name;
          card.artistId = artist._id;
          tab.push(card)
        })
      })
      this.ongoings = tab;
    })


    this.cardService.getSortedCards('last').then((last: any) => {
      this.releases = last.filter((el: Card) => el.price && el.status == 'available' ? el.price > 0 : el.status == 'available');
      this.incomings = last.filter((el: Card) => el.status == 'incoming');
      // this.cardService.getSortedCards('hot').then((hot: any) => {
      //   this.hots = hot
      //   this.cardService.getSortedCards('current').then((current: any) => {
      //     this.ongoings = current
      this.cardService.getSortedCards('selected').then((selected: any) => {
        this.selections = selected;
      });
      //   });
      // });
    });
  }

}
