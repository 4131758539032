import { Component, OnInit } from '@angular/core';
import { Card } from '../models/Card';
import { CardService } from '../services/card.service';
import { FiatService } from '../services/fiat.service';
import { ContractService } from '../services/contract.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  paymentInfos: any;
  load = true;
  item: Card | undefined;
  payId = 'PAY_ID=';
  artworkId = '&ARTWORK_ID=';
  userId = '&USER_ID=';
  userID: string | undefined;
  cardID: string | undefined;
  payID: string | undefined;

  constructor(private fiatService: FiatService, private cardService: CardService, private contractService: ContractService) { }

  ngOnInit(): void {

    this.userID = window.location.href.substring(window.location.href.indexOf(this.userId) + this.userId.length);
    this.cardID = window.location.href.substring(window.location.href.indexOf(this.artworkId) + this.artworkId.length, window.location.href.indexOf(this.userId));
    this.payID = window.location.href.substring(window.location.href.indexOf(this.payId) + this.payId.length, window.location.href.indexOf(this.artworkId));
    this.getPaymentInfos(this.payID);
    this.setCard();
  }

  getPaymentInfos(id: string) {
    this.fiatService.getPaymentInfos(id).then((data: any) => {
      this.paymentInfos = data;
      this.proceedBuyFiat();
    }).catch((error) => {
      console.log(error);
    }).finally(() => {
      this.load = false;
    })
  }

  proceedBuyFiat() {
    if (this.paymentInfos.state == 'approved' && this.payID) {
      this.cardService.getPurchaseByPayId(this.payID)
        .then((purchase) => {
          // if (!purchase && this.cardID && this.userID) {
          //   this.contractService.buyFiat(this.cardID, this.userID, this.payID)
          //     .then(() => {
          //       this.item?.tokenIds?.push('');
          //     })
          //     .catch((err) => {
          //       console.error(err);
          //     })
          // }
        })
        .catch(() => {
          console.log('claqué');
        })


    }
  }

  setCard() {
    this.cardService.getCardById(this.cardID)
      .then((card: any) => {
        this.item = card;
      })
  }

}
