import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LanguagesComponent } from 'src/app/languages/languages.component';
import { LoginComponent } from 'src/app/login/login.component';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth.service';
import { SignupComponent } from 'src/app/signup/signup.component';

@Component({
  selector: 'app-burger-menu',
  templateUrl: './burger-menu.component.html',
  styleUrls: ['./burger-menu.component.scss']
})
export class BurgerMenuComponent implements OnInit {
  user: User | undefined;
  dialogCfg: MatDialogConfig | undefined;
  dial: MatDialog;
  

  constructor(private auth: AuthService, private dialog: MatDialog) {
    this.dial = dialog;
  }

  ngOnInit(): void {
    this.isAuth();
  }

  isAuth() {
    this.auth.getCurrentUser().then((user: any) => {
      this.user = user;
    });
  }

  

  setDialogConfig(side?: string) {
    let cfg = new MatDialogConfig();
    cfg.width = '320px';
    cfg.height = '100vh';
    cfg.position = side == 'right' ? { right: '0', bottom: '0' } : { left: '0', bottom: '0' };
    this.dialogCfg = cfg;
  }

  openSignup(): void {
    const dialogRef = this.dialog.open(SignupComponent);

    dialogRef.updatePosition(this.dialogCfg?.position);
    dialogRef.updateSize(this.dialogCfg?.width, this.dialogCfg?.height);

    dialogRef.afterClosed().subscribe(() => {
      this.isAuth();
    });
  }

  openLanguages(): void {
    const dialogRef = this.dialog.open(LanguagesComponent);

    dialogRef.afterClosed().subscribe(() => {
      this.isAuth();
    });
  }

  openLogin(): void {
    const dialogRef = this.dialog.open(LoginComponent);

    dialogRef.updatePosition(this.dialogCfg?.position);
    dialogRef.updateSize(this.dialogCfg?.width, this.dialogCfg?.height);

    dialogRef.afterClosed().subscribe(() => {
      this.isAuth();
    });
  }

  logout(): void {
    this.auth.logout();
  }
}
