export class Artist {
    _id: string | undefined;
    name: string | undefined;
    url: string | undefined;
    category: string | undefined;
    descriptionEN: string = '';
    descriptionFR: string = '';
    creationDate: Date | undefined;
    modificationDate: Date | undefined;
    active: boolean | undefined;
}