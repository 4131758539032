import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

class TeamCard {
  name!: string;
  url!: string;
  position!: string;
  job!: string;
  buttons!: string[];
}

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {
  cards: TeamCard[];
  aznavourHover = false;
  loadedArray: number[] = [];

  constructor(private translateService: TranslateService) {
    this.cards = [];
    this.translateService.get('Team').subscribe(() => {
      this.cards = this.teamCards();
    });
    this.translateService.onLangChange.subscribe(() => {
      this.cards = this.teamCards();
    })
  }

  ngOnInit(): void {
    window.scrollTo({top: 0, left: 0});
  }

  isLoaded(i: number) {
    this.loadedArray.push(i);
  }

  teamCards(): TeamCard[] {
    return [
      {
        name: 'Moon Boy',
        url: '../../assets/team/fred.jpg',
        position:  `${this.translateService.instant('Team.CEO')}, ${this.translateService.instant('Team.DC')}`,
        job: `${this.translateService.instant('Team.Producer')}`,
        buttons: ['https://www.instagram.com/themoonboywhofelltoearth/'],
      },
      // {
      //   name: 'Axelle Migé',
      //   url: '../../assets/team/axelle.jpg',
      //   position: this.translateService.instant('Team.DAwoman'),
      //   job: this.translateService.instant('Team.Fashion'),
      //   buttons: ['https://www.linkedin.com/in/axelle-migé-a7486698/'],
      // },
      {
        name: 'C. de Gaudusson',
        url: '../../assets/team/clement.jpg',
        position: this.translateService.instant('Team.Board'),
        job: this.translateService.instant('Team.Consultant') + ' (LVMH, Warner Bros...)',
        buttons: ['https://www.linkedin.com/in/cdegaudusson/'],
      },
      {
        name: 'Mischa Aznavour',
        url: '../../assets/team/micha.jpg',
        position: this.translateService.instant('Team.Board'),
        job: this.translateService.instant('Team.MovieProducer'),
        buttons: ['https://www.aznavourfoundation.org/'],
      },
      {
        name: 'Julien Lapierre',
        url: '../../assets/team/julien.jpg',
        position: this.translateService.instant('Team.Designer'),
        job: '',
        buttons: [],
      },
      {
        name: 'Oscar Soccol',
        url: '../../assets/team/oscar.jpg',
        position: this.translateService.instant('Team.Web'),
        job: '',
        buttons: ['https://www.linkedin.com/in/oscar-soccol-335465b1/'],
      },
      {
        name: 'Hava Guerni',
        url: '../../assets/team/hava.jpg',
        position: this.translateService.instant('Team.Blockchain'),
        job: '',
        buttons: ['https://github.com/hguerni'],
      }
    ]
  }
}
