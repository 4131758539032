import { Injectable } from '@angular/core';
import detectEthereumProvider from '@metamask/detect-provider';

@Injectable({
    providedIn: 'root'
})
export class MetamaskService {
    address: string | undefined;
    wallet: string | undefined;

    constructor() { }

    providerExists() {
        return new Promise((resolve, reject) => {
            detectEthereumProvider()
                .then((provider: any) => {
                    provider ? resolve(provider) : reject(-1);
                })
        })
    }
}