import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddArtistComponent } from './admin/add-artist/add-artist.component';
import { AddArtworkComponent } from './admin/add-artwork/add-artwork.component';
import { AdminComponent } from './admin/admin.component';
import { ModifyArtistComponent } from './admin/modify-artist/modify-artist.component';
import { ModifyArtworkComponent } from './admin/modify-artwork/modify-artwork.component';
import { ArtistPageComponent } from './artist-page/artist-page.component';
import { AboutComponent } from './footer/about/about.component';
import { TeamComponent } from './footer/team/team.component';
import { ContactComponent } from './footer/contact/contact.component';
import { TermsComponent } from './footer/terms/terms.component';
import { HomeComponent } from './home/home.component';
import { HelpMetamaskComponent } from './help-metamask/help-metamask.component';
import { SuccessComponent } from './success/success.component';
import { ArtistsListComponent } from './artists-list/artists-list.component';
import { MynstComponent } from './mynst/mynst.component';
import { RecoverComponent } from './recover/recover.component'
import { ConfirmComponent } from './signup/confirm/confirm.component';
import { PurchaseComponent } from './purchase/purchase.component';

const routes: Routes = [
  {path: "", component: HomeComponent},
  {path: "profile", component: MynstComponent},
  {path: "team", component: TeamComponent},
  {path: "about", component: AboutComponent},
  {path: "contact", component: ContactComponent},
  {path: "terms", component: TermsComponent},
  {path: "legal", component: TermsComponent},
  {path: "artists", component: ArtistsListComponent},
  {path: "artist/:id", component: ArtistPageComponent},
  {path: "admin", component: AdminComponent},
  {path: "admin/add-artist", component: AddArtistComponent},
  {path: "admin/add-artwork", component: AddArtworkComponent},
  {path: "admin/modify-artist", component: ModifyArtistComponent},
  {path: "admin/modify-artwork", component: ModifyArtworkComponent},
  {path: "order/:id", component: PurchaseComponent},
  {path: "recover/:id", component: RecoverComponent},
  {path: "confirm/:id", component: ConfirmComponent},
  {path: "help-metamask", component: HelpMetamaskComponent},
  {path: "success", component: SuccessComponent},
  {path: "**", redirectTo: ""}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }