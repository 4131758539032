import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Artist } from 'src/app/models/Artist';
import { ArtistService } from 'src/app/services/artist.service';
import { AuthService } from 'src/app/services/auth.service';
import { UrlService } from 'src/app/services/url.service';

@Component({
  selector: 'app-add-artist',
  templateUrl: './add-artist.component.html',
  styleUrls: ['./add-artist.component.scss']
})
export class AddArtistComponent implements OnInit {
  genres: string[] = [];
  createForm: FormGroup;
  imagePreview: string | undefined;
  isAuth: boolean = false;

  constructor(
    private auth: AuthService,
    private router: Router,
    private urls: UrlService,
    private artistService: ArtistService) {
    if (this.auth.user?.profile == "admin") {
      this.isAuth = true;
    } else {
      this.router.navigate(['**'])
    }
    this.createForm = new FormGroup({
      name: new FormControl('', Validators.minLength(1)),
      image: new FormControl('', Validators.required),
      category: new FormControl('', Validators.required),
      descriptionEN: new FormControl(''),
      descriptionFR: new FormControl(''),
    })
  }

  ngOnInit(): void {
    this.genres = this.urls.getGenres();
  }

  createArtist() {
    let artist = this.setArtist();
    this.artistService.createNewArtistWithFile(artist, [this.createForm.get('image')?.value]).then(() => {
      confirm(artist.name + ' bien ajouté !');
      this.createForm.reset();
      this.router.navigate(['admin'])
      window.scrollTo(0, 0);
    }).catch((error) => {
      console.log(error);

    });
  }

  setArtist(): Artist {
    let artist = new Artist();
    artist.name = this.createForm.get('name')?.value;
    artist.descriptionEN = this.createForm.get('descriptionEN')?.value;
    artist.descriptionFR = this.createForm.get('descriptionFR')?.value;
    artist.category = this.createForm.get('category')?.value;
    artist.url = '';

    return artist;
  }

  onImagePick(event: Event) {
    const htmlElement = (event.target as HTMLInputElement);
    if (htmlElement.files) {
      const file = htmlElement.files[0];
      this.createForm.get('image')?.patchValue(file);
      this.createForm.get('image')?.updateValueAndValidity();
      const reader = new FileReader();
      reader.onload = () => {
        if (this.createForm.get('image')?.valid) {
          this.imagePreview = reader.result as string;
        } else {
          this.imagePreview = undefined;
        }
      };
      reader.readAsDataURL(file);
    }
  }
}
