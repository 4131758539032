import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EmailService } from 'src/app/services/email.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  sentMsg: boolean = false;
  errMsg: boolean = false;

  constructor(private emailService: EmailService) {
    this.contactForm = new FormGroup({
      email: new FormControl('', [Validators.email, Validators.required]),
      subject: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      content: new FormControl('', [Validators.required, Validators.maxLength(500)]),
    })
  }

  ngOnInit(): void {
  }

  sendContactMsg() {
    this.errMsg = false;
    this.sentMsg = false;
    this.emailService.sendContactMail(this.contactForm.get('email')?.value,this.contactForm.get('subject')?.value,this.contactForm.get('content')?.value, )
      .then((success) => {
        console.log(success);
        this.sentMsg = true;
      }).catch((err) => {
        this.errMsg = true;
      })
  }
}
