import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { LanguagesComponent } from '../languages/languages.component';
import { LoginComponent } from '../login/login.component';
import { User } from '../models/User';
import { AuthService } from '../services/auth.service';
import { EmailService } from '../services/email.service';
import { UrlService } from '../services/url.service';
import { SignupComponent } from '../signup/signup.component';
import { BurgerMenuComponent } from './burger-menu/burger-menu.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  emailForm: FormGroup;
  saveSuccess: boolean = false;
  genres: string[] = [];
  user: User | undefined;
  dialogCfg: MatDialogConfig | undefined;

  constructor(private emailService: EmailService, private urls: UrlService,
    private auth: AuthService,
    private dialog: MatDialog) {
    this.emailForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    });
  }

  ngOnInit(): void {
    this.genres = this.urls.getGenres();
    this.isAuth();
    this.setDialogConfig();
  }

  setDialogConfig(side?: string) {
    let cfg = new MatDialogConfig();
    cfg.width = '320px';
    cfg.height = '100vh';
    cfg.position = side == 'right' ? { right: '0', bottom: '0'} : { left: '0', bottom: '0'} ;
    this.dialogCfg = cfg;
  }

  saveEmail() {
    this.emailService.saveEmail(this.emailForm.get('email')?.value)
      .then(
        () => {
          this.saveSuccess = true;
          setTimeout(() => {
            this.emailForm.reset();
            this.saveSuccess = false;
          }, 3000);
        })
      .catch(
        (error) => {
          console.log(error);
        }
      )
  }

  openSignup(): void {
    const dialogRef = this.dialog.open(SignupComponent);

    dialogRef.updatePosition(this.dialogCfg?.position);
    dialogRef.updateSize(this.dialogCfg?.width, this.dialogCfg?.height);

    dialogRef.afterClosed().subscribe(() => {
      this.isAuth();
    });
  }

  openLanguages(): void {
    const dialogRef = this.dialog.open(LanguagesComponent);

    dialogRef.afterClosed().subscribe(() => {
      this.isAuth();
    });
  }

  openLogin(): void {
    const dialogRef = this.dialog.open(LoginComponent);

    dialogRef.updatePosition(this.dialogCfg?.position);
    dialogRef.updateSize(this.dialogCfg?.width, this.dialogCfg?.height);

    dialogRef.afterClosed().subscribe(() => {
      this.isAuth();
    });
  }

  logout(): void {
    this.auth.logout();
  }

  openMenu() {
    this.setDialogConfig('left');
    const dialogRef = this.dialog.open(BurgerMenuComponent);

    dialogRef.updatePosition(this.dialogCfg?.position);
    dialogRef.updateSize(this.dialogCfg?.width, this.dialogCfg?.height);
    dialogRef.afterClosed().subscribe(() => {
      this.isAuth();
    });
  }

  isAuth() {
    this.auth.getCurrentUser().then((user: any) => {
      this.user = user;
    });
  }
}
