import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-access-screen',
  templateUrl: './access-screen.component.html',
  styleUrls: ['./access-screen.component.scss']
})
export class AccessScreenComponent implements OnInit {
  @Output()
  pass: EventEmitter<boolean> = new EventEmitter();

  pw: string = 'Neo-pass';

  constructor() { }

  ngOnInit(): void {
  }

  verifyAccess(event: any) {
    if (this.pw === event.target.value) {
      this.pass.emit(true);
    }
  }

}
