import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-metamask',
  templateUrl: './help-metamask.component.html',
  styleUrls: ['./help-metamask.component.scss']
})
export class HelpMetamaskComponent implements OnInit {
  smartphone: boolean = false;
  compatibleBrowser: boolean = true;

  constructor() { }

  ngOnInit(): void {
    this.smartphone = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    this.compatibleBrowser = /Chrome|Firefox|Edge/i.test(navigator.userAgent);
    window.scrollTo(0, 0);
  }

  setSmartphone(bool: boolean) {
    this.smartphone = bool;
  }

}
