import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UrlService } from '../services/url.service';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss']
})
export class LanguagesComponent implements OnInit {
  flagUrls: string[] = [];

  constructor(private urls: UrlService,
    private dialog: MatDialog,
    private translateService: TranslateService,) { }

  ngOnInit(): void {
    this.setFlags();
  }

  setFlags() {
    this.flagUrls = this.urls.getFlagsIconsUrlList();
  }

  translate(str: string): void {
    this.translateService.use(str.slice(str.length - 6, str.length - 4));
    this.dialog.closeAll();
  }

}
