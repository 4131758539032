import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UrlService {

    constructor() { }

    getGenres(): string[] {
        return ['pop', 'fun', 'hip', 'roc', 'jaz', 'pho', 'vid', 'fas']; // 'ill'
    }

    getFlagsIconsUrlList(): string[] {
        return [
            'assets/flags/en.png',
            'assets/flags/fr.png',
            // 'assets/flags/ru.png',
            'assets/flags/ch.png'
        ]
    }

    getSocialNetworksIconsUrlList(): string[] {
        return [
            'assets/icons/facebook.png',
            'assets/icons/instagram.png',
            'assets/icons/twitter.png'
        ]
    }

    getImagesUrlList(): string[] {
        return [
            'assets/img/css.jpeg',
            'assets/img/js.jpeg',
            'assets/img/swift.jpeg'
        ]
    }
}