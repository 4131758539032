import { Component, OnInit } from '@angular/core';

class LegalDoc {
  trKey: string = '';
  src?: string;
}

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  docs: LegalDoc[] = [];
  selectedDocIndex = -1;
  loaded = false;

  constructor() { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.loadDocs()
  }

  loadDocs() {
    this.docs = [
      {
        trKey: 'Terms.CGUCGV',
        src: 'cgu.pdf'
      },
      {
        trKey: 'Terms.Confidentiality',
        src: 'confidentiality.pdf'
      },
      {
        trKey: 'Terms.RGPD',
        src: 'RGPD.pdf'
      },
      {
        trKey: 'Terms.LegalMentions',
        src: 'legal-mentions.pdf'
      },
      {
        trKey: 'Terms.Cookies',
        src: 'cookies.pdf'
      }
    ];
    this.loaded = true;
  }

  openDoc(doc: LegalDoc) {
    window.open('../../../assets/terms/' + doc.src, '_blank')
  }

}
