import { Component, HostListener, OnInit } from '@angular/core';
import { Artist } from '../models/Artist';
import { ArtistService } from '../services/artist.service';

@Component({
  selector: 'app-artists-list',
  templateUrl: './artists-list.component.html',
  styleUrls: ['./artists-list.component.scss']
})
export class ArtistsListComponent implements OnInit {
  artists: Artist[] | undefined;
  displayedArtists: Artist[] | undefined;
  lastSearch = '';
  pagination: number = 5;
  constructor(private artistService: ArtistService) { }

  ngOnInit(): void {
    this.setArtist();
    window.scrollTo(0, 0);
  }

  // Get & set all artists
  setArtist() {
    this.artistService.getArtists().then((data: any) => {
      this.artists = data;
      this.displayedArtists = this.artists?.filter((artist, i) => i<data.length); // replace data.lenght by this.pagination
    });
  }

  // Search input filter
  search(event: any) {
    this.lastSearch = event.target.value;
    this.displayedArtists = this.artists?.filter((artist, i) => artist.name?.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase()))
  }

  @HostListener("window:scroll", ["$event"])
  onScroll() {
    let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.clientHeight;
    let max = document.documentElement.scrollHeight;
    if (pos+1 >= max) {
      let l = this.displayedArtists?.length || 0;
      if (this.lastSearch=='') this.displayedArtists = this.artists?.filter((artist, i) => i<l+5);
    }
  }


}
