import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Artist } from 'src/app/models/Artist';
import { ArtistService } from 'src/app/services/artist.service';
import { AuthService } from 'src/app/services/auth.service';
import { UrlService } from 'src/app/services/url.service';
import { ReducedArtist } from '../add-artwork/add-artwork.component';

@Component({
  selector: 'app-modify-artist',
  templateUrl: './modify-artist.component.html',
  styleUrls: ['./modify-artist.component.scss']
})
export class ModifyArtistComponent implements OnInit {
  genres: string[] = []; 
  modifyForm: FormGroup;
  imagePreview: string | undefined = '';
  isAuth: boolean = false;
  artists: ReducedArtist[] = [];
  artist: Artist | undefined;

  constructor(
    private auth: AuthService,
    private router: Router,
    private urls: UrlService,
    private artistService: ArtistService) {
    if (this.auth.user?.profile == "admin") {
      this.isAuth = true;
    } else {
      this.router.navigate(['**'])
    }
    this.modifyForm = new FormGroup({
      artist: new FormControl('', Validators.required),
      name: new FormControl('', [Validators.required, Validators.minLength(1)]),
      image: new FormControl('', Validators.required),
      category: new FormControl('', Validators.required),
      descriptionEN: new FormControl(''),
      descriptionFR: new FormControl(''),
    })
  }

  ngOnInit(): void {
    this.genres = this.urls.getGenres();
    this.artistService.getAllArtists().then((artists: any) => this.artists = artists);
  }

  modifyArtist() {
    let artist = this.setArtist();
    if(this.artist?._id){
      this.artistService.modifyArtistWithFile(this.artist._id, artist, typeof this.modifyForm.get('image')?.value != 'string' ? [this.modifyForm.get('image')?.value] : this.modifyForm.get('image')?.value).then(() => {
        confirm(artist.name + ' bien modifié !');
        this.modifyForm.reset();
        this.router.navigate(['admin'])
        window.scrollTo(0, 0);
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  setArtist(): Artist {
    let artist = new Artist();
    artist.name = this.modifyForm.get('name')?.value;
    artist.descriptionFR = this.modifyForm.get('descriptionFR')?.value;
    artist.descriptionEN = this.modifyForm.get('descriptionEN')?.value;
    artist.category = this.modifyForm.get('category')?.value;
    artist.url = '';

    return artist;
  }

  onImagePick(event: Event) {
    const htmlElement = (event.target as HTMLInputElement);
    if (htmlElement.files) {
      const file = htmlElement.files[0];
      this.modifyForm.get('image')?.patchValue(file);
      this.modifyForm.get('image')?.updateValueAndValidity();
      const reader = new FileReader();
      reader.onload = () => {
        if (this.modifyForm.get('image')?.valid) {
          this.imagePreview = reader.result as string;
        } else {
          this.imagePreview = undefined;
        }
      };
      reader.readAsDataURL(file);
    }
  }

  fillArtist() {
    this.artistService.getArtistById(this.modifyForm.get('artist')?.value).then((artist: any) => {
      this.artist = new Artist();
      this.artist._id = artist._id;
      this.artist.name = artist.name;
      this.artist.descriptionEN = artist.descriptionEN;
      this.artist.descriptionFR = artist.descriptionFR;
      this.artist.category = artist.category;
      this.artist.url = artist.url;
      this.fillForm();
      this.imagePreview = artist.url;
    })
  }

  fillForm() {
    this.modifyForm.get('name')?.setValue(this.artist?.name);
    this.modifyForm.get('descriptionEN')?.setValue(this.artist?.descriptionEN);
    this.modifyForm.get('descriptionFR')?.setValue(this.artist?.descriptionFR);
    this.modifyForm.get('category')?.setValue(this.artist?.category);
    this.modifyForm.get('image')?.setValue(this.artist?.url);
  }

  deleteArtist() {
    if(confirm('Etes vous sûr de vouloir supprimer cet artiste ainsi que les oeuvres qui lui sont associées?')){
      if(this.artist?._id)
      this.artistService.deleteArtist(this.artist?._id).then(() => {
        confirm(this.artist?.name + ' bien supprimé !');
        this.modifyForm.reset();
        this.router.navigate(['admin'])
        window.scrollTo(0, 0);
      }, (error) => {
        confirm(error.error.error.message);
      });;
    }
  }

}
