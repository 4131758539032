export const environment = {
  production: false,
  protocol: 'https',
  path: 'neosatellite.io',
  invoicePath: 'neosatellite.vosfactures.fr',
  invoiceApiKey: 'B53E17NIe1kXptTaoIU',
  // pps: 'EIzr7JwnsOd_c2gAzGT9waPH-jWuMU1xoQHq0By2UwLThR2E2kuKXa6H73Gz33QSD5-TsVIZdKmSuKdi', // test
  // ppc: 'AVT7vuZ55VdpclTb2vaK-86SkwsZOO2Q1-90-ZpF1npsfhJFzfAOxlCxFSMAHc-JLYS8jb3hKFyjvRGD', // test
  ppc: 'AbyaGctSA_25ENPS3t7jny7VWk9HXy2LjCUGSdi6kq_vsjGGRvBXkuBKL6VbH1XT00tqsCvvSM57GWSD',
  pps: 'ENdokYVT-rCOxyeP7QbjZY6sCK28pIXMOOmk6dNQCCWt2WLiULuWnT776AT0IgPXGNuGN_cMU5-62RCE'
};
