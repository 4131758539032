import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-edit-email',
  templateUrl: './edit-email.component.html',
  styleUrls: ['./edit-email.component.scss']
})
export class EditEmailComponent implements OnInit {
  user: User | undefined;
  mailForm: FormGroup;
  success: boolean = false;
  error: string | undefined;

  constructor(private authService: AuthService, private dialog: MatDialog, private translateService: TranslateService) {
    this.mailForm = new FormGroup({
      current: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]),
      email: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(50), Validators.email])
    })
  }

  ngOnInit(): void {
    this.authService.getCurrentUser().then((user: any) => {
      this.user = user;
    });

    this.mailForm.valueChanges.subscribe((change) => {
      this.error = undefined;
    })
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  editEmail() {
    if (this.user?._id)
    this.authService.editEmail(this.user?._id, this.mailForm.get('current')?.value, this.mailForm.get('email')?.value)
      .then((data) => {
        if (data) {
          this.success = true;
        }
      })
      .catch((error) => {
        if (error.error == -1) this.error = this.translateService.instant('Profile.WrongPw');
        if (error.error == -2) this.error = this.translateService.instant('Profile.ServerErr');
      })
  }
}
