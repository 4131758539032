import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../services/auth.service';
import { EmailService } from '../services/email.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  forgotten: boolean = false;
  mailSent: boolean = false;
  form: FormGroup;
  mailForm: FormGroup;
  mailServiceResponse: string = '';
  wrongLog = false;
  notConfirmed = false;
  wrongMail = false;


  constructor(private auth: AuthService, private dialog: MatDialog, private emailService: EmailService) {
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)])
    });
    this.mailForm = new FormGroup({
      mail: new FormControl('', [Validators.required, Validators.email])
    })
  }

  onLogin() {
    this.wrongLog = false;
    this.auth.login(
      this.form.get('email')?.value,
      this.form.get('password')?.value
    ).then(
      (existingAccount: any) => {
        if (existingAccount == this.form.get('email')?.value || existingAccount.error) {
          this.notConfirmed = true;
        } else {
          this.auth.isAuth$.subscribe(() => {
            this.dialog.closeAll();
          });
        }
      }
    ).catch(
      (error) => {
        this.wrongLog = true;
      }
    );
  }

  forgetPw() {
    this.forgotten = true;
  }

  sendMail() {
    this.wrongMail = false;
    // check if mail is present  
    this.auth.isMailPresent(this.mailForm.get('mail')?.value).then(() => {
      this.auth.sendMailTo(this.mailForm.get('mail')?.value).then((res: any) => {
        this.mailServiceResponse = res.message;
        this.mailSent = true;
      }).catch(() => console.log('Mail non valide'));

    }).catch((error) => {
      this.wrongMail = true;
    });
  }

  sendConfirm() {
    this.emailService.sendConfirmLink(this.form.get('email')?.value).then((res: any) => {
      this.mailServiceResponse = res.message;
      this.mailSent = true;
    }).catch((err) => console.log(err))
  }

}
