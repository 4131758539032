import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent {
  isAuth: boolean = false;
  marginLimit = 10;

  constructor(private auth: AuthService, private router: Router) {
    console.log(this.auth.user);
    
    if (this.auth.user?.profile == "admin" && this.auth.user?._id) {
      this.isAuth = true;
      this.auth.getAdminInfos(this.auth.user._id).then((infos: any) => {
        this.marginLimit = infos.marginLimit;
      }
      ).catch((err) => console.log(err));
    } else {
      this.router.navigate(['**'])
    }
  }

  updateNewMargin() {
    if (this.auth.user?._id)
    this.auth.updateAdminInfos(this.auth.user._id, this.marginLimit).then(() => {
      confirm('Bien modifié')
    }).catch(() => confirm('ERREUR, pas pris en compte'));
  }
}
